import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex flex-row flex-wrap gap-2 w-full h-max px-2" };
const _hoisted_2 = { class: "flex flex-row flex-wrap gap-2 w-full h-max px-2 mt-2" };
const _hoisted_3 = {
    key: 0,
    class: "flex align-items-center justify-content-center"
};
const _hoisted_4 = {
    for: "ingredient1",
    class: "m-0 text-xs"
};
import { onMounted, ref, watch, computed } from "vue";
import CardAnswer from "@/binaplan/components/autoKitchen/requestBlock/content/CardAnswer.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'radioBlock',
    props: {
        data: {},
        stepId: {},
        request: {}
    },
    emits: ["checkedAnswer"],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const ingredient = ref(props.data.questions[0].answers[0].id);
        const inputSwitch = ref(false);
        const answers = ref([]);
        const attribute = ref([]);
        const visibleAttribute = computed(() => {
            return false;
        });
        const getAnswer = (id) => {
            const step = props.request.find((step) => step.id === props.data.id);
            if (step) {
                const newStep = {
                    id: step.id,
                    questions: [],
                };
                for (const quest of step.questions) {
                    const newQuest = {
                        id: quest.id,
                        answers: [],
                    };
                    for (const answer of quest.answers) {
                        const newAnswer = {
                            id: answer.id,
                        };
                        if (answer.value) {
                            newAnswer.value = answer.value;
                        }
                        newQuest.answers.push(newAnswer);
                    }
                    newStep.questions.push(newQuest);
                }
                const questionId = props.data.questions.find((quest) => quest.answers.find((answer) => answer.id === id))?.id;
                if (questionId) {
                    const questResp = newStep.questions.find((quest) => quest.id === questionId);
                    if (questResp) {
                        questResp.answers[0].id = id;
                    }
                }
                console.log(newStep);
                return newStep;
            }
        };
        const initEmit = (data) => {
            emit("checkedAnswer", data);
        };
        onMounted(() => {
            if (props.data.questions[0].attributes) {
                // console.log(props.data.questions[0].attributes);
            }
            for (const answer of props.data.questions[0].answers) {
                answers.value.push({
                    id: answer.id,
                    type: props.data.questions[0].type,
                    params: {
                        title: answer.params.title,
                        description: answer.params.description,
                        price: answer.params.price,
                        image: answer.params.image,
                    },
                });
            }
            initEmit(getAnswer(answers.value[0].id));
        });
        watch(ingredient, (newChecked) => {
            initEmit(getAnswer(newChecked));
        });
        return (_ctx, _cache) => {
            const _component_RadioButton = _resolveComponent("RadioButton");
            const _component_InputSwitch = _resolveComponent("InputSwitch");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(answers.value, (answer) => {
                        return (_openBlock(), _createBlock(CardAnswer, {
                            key: answer.id,
                            answer: answer
                        }, _createSlots({ _: 2 }, [
                            (answer.type === 'radio')
                                ? {
                                    name: "radio",
                                    fn: _withCtx(() => [
                                        _createVNode(_component_RadioButton, {
                                            class: "absolute left-0 top-0",
                                            modelValue: ingredient.value,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((ingredient).value = $event)),
                                            name: "pizza",
                                            value: answer.id,
                                            pt: {
                                                root: {
                                                    style: {
                                                        width: '100%',
                                                        height: '100%',
                                                    },
                                                },
                                                box: {
                                                    style: {
                                                        position: 'absolute',
                                                        left: '10px',
                                                        top: '10px',
                                                    },
                                                },
                                            }
                                        }, null, 8, ["modelValue", "value"])
                                    ]),
                                    key: "0"
                                }
                                : undefined
                        ]), 1032, ["answer"]));
                    }), 128))
                ]),
                _createElementVNode("div", _hoisted_2, [
                    (_ctx.data.questions[0].attributes.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_InputSwitch, {
                                class: "mr-2",
                                inputId: "ingredient1",
                                modelValue: inputSwitch.value,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((inputSwitch).value = $event))
                            }, null, 8, ["modelValue"]),
                            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.data.questions[0].attributes[0].title), 1)
                        ]))
                        : _createCommentVNode("", true)
                ])
            ], 64));
        };
    }
});

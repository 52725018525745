import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "topbar_style w-full flex justify-content-between align-items-center" };
const _hoisted_2 = { class: "search_style" };
const _hoisted_3 = { class: "account_style flex align-items-center justify-content-between" };
import { ref } from "vue";
import SelectLanguage from "@/components/menu/selectLanguage/SelectLanguage.vue";
import AccountMenu from "@/components/menu/accountMenu/AccountMenu.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'TopBar',
    setup(__props) {
        const value1 = ref(null);
        return (_ctx, _cache) => {
            const _component_InputIcon = _resolveComponent("InputIcon");
            const _component_InputText = _resolveComponent("InputText");
            const _component_IconField = _resolveComponent("IconField");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_IconField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputIcon, { class: "pi pi-search" }),
                            _createVNode(_component_InputText, {
                                class: "input-search-style",
                                style: { "width": "100%", "height": "100%" },
                                modelValue: value1.value,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((value1).value = $event)),
                                placeholder: "Поиск..."
                            }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                    })
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(SelectLanguage),
                    _createVNode(AccountMenu)
                ])
            ]));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "languageCodeStyle" };
const _hoisted_2 = { class: "iconStyle overflow-hidden flex align-items-center justify-content-center" };
const _hoisted_3 = {
    xmlns: "http://www.w3.org/2000/svg",
    version: "1.1",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    width: "16",
    height: "16",
    x: "0",
    y: "0",
    viewBox: "0 0 512 512",
    style: { "enable-background": "new 0 0 512 512" },
    "xml:space": "preserve",
    class: ""
};
const _hoisted_4 = {
    xmlns: "http://www.w3.org/2000/svg",
    version: "1.1",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    width: "16",
    height: "16",
    x: "0",
    y: "0",
    viewBox: "0 0 512 512",
    style: { "enable-background": "new 0 0 512 512" },
    "xml:space": "preserve",
    class: ""
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = { class: "iconStyle overflow-hidden flex align-items-center justify-content-center" };
const _hoisted_7 = {
    xmlns: "http://www.w3.org/2000/svg",
    version: "1.1",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    width: "16",
    height: "16",
    x: "0",
    y: "0",
    viewBox: "0 0 512 512",
    style: { "enable-background": "new 0 0 512 512" },
    "xml:space": "preserve",
    class: ""
};
const _hoisted_8 = {
    xmlns: "http://www.w3.org/2000/svg",
    version: "1.1",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    width: "16",
    height: "16",
    x: "0",
    y: "0",
    viewBox: "0 0 512 512",
    style: { "enable-background": "new 0 0 512 512" },
    "xml:space": "preserve",
    class: ""
};
const _hoisted_9 = { class: "flex align-items-center justify-content-between flex-grow-1" };
const _hoisted_10 = { class: "ml-2" };
const _hoisted_11 = { class: "ml-2" };
import { ref } from "vue";
import Lang from "@/plugins/Lang";
export default /*@__PURE__*/ _defineComponent({
    __name: 'SelectLanguage',
    setup(__props) {
        const menu = ref();
        const languages = ref(Lang.getLanguages());
        const selectedLanguageCode = ref(Lang.getLocale().toUpperCase());
        const selectLanguage = (code) => {
            Lang.setLocale(code);
            window.location.reload();
        };
        const toggle = (event) => {
            menu.value.toggle(event);
        };
        return (_ctx, _cache) => {
            const _component_InputIcon = _resolveComponent("InputIcon");
            const _component_Menu = _resolveComponent("Menu");
            const _directive_ripple = _resolveDirective("ripple");
            return (_openBlock(), _createElementBlock("div", {
                class: "user-select-none flex align-items-center justify-content-center cursor-pointer",
                style: { "gap": "0.625rem" },
                onClick: toggle,
                "aria-haspopup": "true",
                "aria-controls": "overlay_menu"
            }, [
                _createElementVNode("span", _hoisted_1, _toDisplayString(selectedLanguageCode.value), 1),
                _createElementVNode("div", _hoisted_2, [
                    (selectedLanguageCode.value === 'RU')
                        ? (_openBlock(), _createBlock(_component_InputIcon, { key: 0 }, {
                            default: _withCtx(() => [
                                (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[0] || (_cache[0] = [
                                    _createElementVNode("g", null, [
                                        _createElementVNode("circle", {
                                            cx: "256",
                                            cy: "256",
                                            r: "256",
                                            fill: "#f0f0f0",
                                            opacity: "1",
                                            "data-original": "#f0f0f0",
                                            class: ""
                                        }),
                                        _createElementVNode("path", {
                                            fill: "#0052b4",
                                            d: "M496.077 345.043C506.368 317.31 512 287.314 512 256s-5.632-61.31-15.923-89.043H15.923C5.633 194.69 0 224.686 0 256s5.633 61.31 15.923 89.043L256 367.304z",
                                            opacity: "1",
                                            "data-original": "#0052b4",
                                            class: ""
                                        }),
                                        _createElementVNode("path", {
                                            fill: "#d80027",
                                            d: "M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512z",
                                            opacity: "1",
                                            "data-original": "#d80027"
                                        })
                                    ], -1)
                                ])))
                            ]),
                            _: 1
                        }))
                        : (selectedLanguageCode.value === 'EN')
                            ? (_openBlock(), _createBlock(_component_InputIcon, { key: 1 }, {
                                default: _withCtx(() => [
                                    (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[1] || (_cache[1] = [
                                        _createElementVNode("g", null, [
                                            _createElementVNode("circle", {
                                                cx: "256",
                                                cy: "256",
                                                r: "256",
                                                fill: "#f0f0f0",
                                                opacity: "1",
                                                "data-original": "#f0f0f0",
                                                class: ""
                                            }),
                                            _createElementVNode("g", { fill: "#d80027" }, [
                                                _createElementVNode("path", {
                                                    d: "M244.87 256H512c0-23.106-3.08-45.49-8.819-66.783H244.87zM244.87 122.435h229.556a257.35 257.35 0 0 0-59.07-66.783H244.87zM256 512c60.249 0 115.626-20.824 159.356-55.652H96.644C140.374 491.176 195.751 512 256 512zM37.574 389.565h436.852a254.474 254.474 0 0 0 28.755-66.783H8.819a254.474 254.474 0 0 0 28.755 66.783z",
                                                    fill: "#d80027",
                                                    opacity: "1",
                                                    "data-original": "#d80027",
                                                    class: ""
                                                })
                                            ]),
                                            _createElementVNode("path", {
                                                fill: "#0052b4",
                                                d: "M118.584 39.978h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037a257.407 257.407 0 0 0-49.652 55.337h7.475l-13.813 10.035a255.58 255.58 0 0 0-6.194 10.938l6.596 20.301-12.306-8.941a253.567 253.567 0 0 0-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444A258.468 258.468 0 0 0 0 256h256V0c-50.572 0-97.715 14.67-137.416 39.978zm9.918 190.422-21.699-15.765L85.104 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zM220.328 230.4l-21.699-15.765L176.93 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm0-74.574 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822z",
                                                opacity: "1",
                                                "data-original": "#0052b4"
                                            })
                                        ], -1)
                                    ])))
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true)
                ]),
                _createVNode(_component_Menu, {
                    ref_key: "menu",
                    ref: menu,
                    id: "overlay_menu",
                    model: languages.value,
                    popup: true,
                    pt: {
                        menu: {
                            style: 'padding: 0; padding-top: 10.5px; margin: 0; width: 216px',
                        },
                    }
                }, {
                    item: _withCtx(({ item }) => [
                        _withDirectives((_openBlock(), _createElementBlock("a", {
                            class: "user-select-none languageBtn flex align-items-center cursor-pointer",
                            onClick: ($event) => (selectLanguage(item.code))
                        }, [
                            _createElementVNode("div", _hoisted_6, [
                                (item.code.toUpperCase() === 'RU')
                                    ? (_openBlock(), _createBlock(_component_InputIcon, { key: 0 }, {
                                        default: _withCtx(() => [
                                            (_openBlock(), _createElementBlock("svg", _hoisted_7, _cache[2] || (_cache[2] = [
                                                _createElementVNode("g", null, [
                                                    _createElementVNode("circle", {
                                                        cx: "256",
                                                        cy: "256",
                                                        r: "256",
                                                        fill: "#f0f0f0",
                                                        opacity: "1",
                                                        "data-original": "#f0f0f0",
                                                        class: ""
                                                    }),
                                                    _createElementVNode("path", {
                                                        fill: "#0052b4",
                                                        d: "M496.077 345.043C506.368 317.31 512 287.314 512 256s-5.632-61.31-15.923-89.043H15.923C5.633 194.69 0 224.686 0 256s5.633 61.31 15.923 89.043L256 367.304z",
                                                        opacity: "1",
                                                        "data-original": "#0052b4",
                                                        class: ""
                                                    }),
                                                    _createElementVNode("path", {
                                                        fill: "#d80027",
                                                        d: "M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512z",
                                                        opacity: "1",
                                                        "data-original": "#d80027"
                                                    })
                                                ], -1)
                                            ])))
                                        ]),
                                        _: 1
                                    }))
                                    : (item.code.toUpperCase() === 'EN')
                                        ? (_openBlock(), _createBlock(_component_InputIcon, { key: 1 }, {
                                            default: _withCtx(() => [
                                                (_openBlock(), _createElementBlock("svg", _hoisted_8, _cache[3] || (_cache[3] = [
                                                    _createElementVNode("g", null, [
                                                        _createElementVNode("circle", {
                                                            cx: "256",
                                                            cy: "256",
                                                            r: "256",
                                                            fill: "#f0f0f0",
                                                            opacity: "1",
                                                            "data-original": "#f0f0f0",
                                                            class: ""
                                                        }),
                                                        _createElementVNode("g", { fill: "#d80027" }, [
                                                            _createElementVNode("path", {
                                                                d: "M244.87 256H512c0-23.106-3.08-45.49-8.819-66.783H244.87zM244.87 122.435h229.556a257.35 257.35 0 0 0-59.07-66.783H244.87zM256 512c60.249 0 115.626-20.824 159.356-55.652H96.644C140.374 491.176 195.751 512 256 512zM37.574 389.565h436.852a254.474 254.474 0 0 0 28.755-66.783H8.819a254.474 254.474 0 0 0 28.755 66.783z",
                                                                fill: "#d80027",
                                                                opacity: "1",
                                                                "data-original": "#d80027",
                                                                class: ""
                                                            })
                                                        ]),
                                                        _createElementVNode("path", {
                                                            fill: "#0052b4",
                                                            d: "M118.584 39.978h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037a257.407 257.407 0 0 0-49.652 55.337h7.475l-13.813 10.035a255.58 255.58 0 0 0-6.194 10.938l6.596 20.301-12.306-8.941a253.567 253.567 0 0 0-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444A258.468 258.468 0 0 0 0 256h256V0c-50.572 0-97.715 14.67-137.416 39.978zm9.918 190.422-21.699-15.765L85.104 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zM220.328 230.4l-21.699-15.765L176.93 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm0-74.574 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822z",
                                                            opacity: "1",
                                                            "data-original": "#0052b4"
                                                        })
                                                    ], -1)
                                                ])))
                                            ]),
                                            _: 1
                                        }))
                                        : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("span", _hoisted_10, _toDisplayString(item.name), 1),
                                _createElementVNode("span", _hoisted_11, _toDisplayString(item.code.toUpperCase()), 1)
                            ])
                        ], 8, _hoisted_5)), [
                            [_directive_ripple]
                        ])
                    ]),
                    _: 1
                }, 8, ["model"])
            ]));
        };
    }
});

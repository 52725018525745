import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-row gap-2 align-items-start justify-content-start" };
const _hoisted_2 = { class: "flex flex-column align-items-center justify-content-center" };
const _hoisted_3 = { class: "h-1rem" };
const _hoisted_4 = {
    key: 0,
    class: "flex flex-column align-items-start justify-content-start"
};
const _hoisted_5 = { style: { "font-size": "10px" } };
const _hoisted_6 = {
    style: { "font-size": "12px" },
    class: "font-semibold"
};
const _hoisted_7 = {
    key: 1,
    class: "w-10rem"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'ItemResponse',
    props: {
        item: {}
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            const _component_Avatar = _resolveComponent("Avatar");
            const _component_Divider = _resolveComponent("Divider");
            const _component_Skeleton = _resolveComponent("Skeleton");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_Avatar, {
                        icon: props.item.title != undefined && props.item.response != undefined
                            ? 'pi pi-check'
                            : 'pi pi-hourglass',
                        style: { "background-color": "#dee9fc", "color": "#ffae3dff", "border": "solid 2px #ffae3dff" },
                        shape: "circle"
                    }, null, 8, ["icon"]),
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_Divider, { layout: "vertical" })
                    ])
                ]),
                (_ctx.item.title && _ctx.item.response)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(props.item.title), 1),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(props.item.response), 1)
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_Skeleton, { height: "2rem" })
                    ]))
            ]));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "px-3 flex align-items-center justify-content-end flex-grow-1" };
const _hoisted_2 = { class: "font-bold white-space-nowrap mr-2" };
const _hoisted_3 = { class: "relative w-full h-full flex flex-row" };
const _hoisted_4 = { class: "relative flex-grow-1 flex flex-column gap-2 h-full bg-pink-50" };
const _hoisted_5 = {
    key: 0,
    class: "border-2 border-black-alpha-20 border-radius-md overflow-hidden",
    style: { "max-width": "500px", "min-height": "max-content" }
};
const _hoisted_6 = {
    key: 1,
    style: { "max-width": "500px", "min-height": "max-content" },
    class: "border-2 border-black-alpha-20 border-radius-md overflow-hidden"
};
const _hoisted_7 = ["src", "alt"];
const _hoisted_8 = ["src", "alt"];
const _hoisted_9 = {
    key: 2,
    class: "border-2 flex-grow-1 border-black-alpha-20 border-radius-md overflow-hidden",
    style: { "max-width": "500px" }
};
const _hoisted_10 = { class: "h-full overflow-y-scroll scroll" };
const _hoisted_11 = { class: "flex flex-column py-2" };
const _hoisted_12 = { class: "pl-3" };
const _hoisted_13 = { class: "pl-3" };
const _hoisted_14 = { class: "pl-3" };
const _hoisted_15 = { class: "mr-2" };
const _hoisted_16 = { class: "flex sm:flex-column md:flex-column lg:flex-column xl:flex-row" };
const _hoisted_17 = {
    key: 0,
    class: "absolute h-full w-full flex align-items-center justify-content-center z-4 bg-black-alpha-20"
};
const _hoisted_18 = { class: "flex gap-2" };
const _hoisted_19 = {
    key: 0,
    class: "flex flex-column"
};
const _hoisted_20 = { class: "text-xs" };
const _hoisted_21 = { class: "flex gap-2" };
import { ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
import AK from "@/module/AutoKitchen";
import ResponseBlock from "@/binaplan/components/autoKitchen/ResponseBlock.vue";
import RequestBlock from "@/binaplan/components/autoKitchen/requestBlock/RequestBlock.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'TestForm',
    props: {
        activeTestForm: { type: Boolean },
        collectionId: {},
        collectionCode: {}
    },
    emits: ["setStateForm"],
    setup(__props, { emit: __emit }) {
        const toast = useToast();
        const isGenerateImage = ref(false);
        const imageUrl = ref([]);
        const price = ref(0.0);
        const estimate = ref([]);
        const requestId = ref(27);
        const statusBarData = ref({ title: "", status: "" });
        const dataResponse = ref([]);
        const newDataRequest = ref([]);
        const props = __props;
        const active = ref(props.activeTestForm);
        const emit = __emit;
        const close = () => {
            active.value = false;
        };
        const testExportRequest = () => {
            for (const item of newDataRequest.value) {
                console.log(JSON.stringify(item));
                // for (const quest of item.questions) {
                //   console.log(JSON.stringify(quest));
                // }
            }
        };
        const exportRequest = async () => {
            isGenerateImage.value = true;
            imageUrl.value = [];
            estimate.value = [];
            const req = [];
            for (const item of newDataRequest.value) {
                for (const quest of item.questions) {
                    req.push(quest);
                }
            }
            console.log("req", JSON.stringify(req));
            statusBarData.value.title = "Очистка сцены";
            statusBarData.value.status = "Ожидание";
            const test = await AK.removeObjectSceneWebPlanner(requestId.value);
            if (test) {
                statusBarData.value.title = "Очистка сцены";
                statusBarData.value.status = test.getType();
            }
            const result = await AK.addRequest(props.collectionCode, req, requestId.value);
            if (result) {
                const request = result.getResponse().request;
                statusBarData.value.title = "Сборка сцены";
                statusBarData.value.status = request.status;
                let imageStatus;
                const checkImageStatus = async () => {
                    const data = await AK.getImageGenerate(request.id);
                    const response = data?.getResponse();
                    imageStatus = response.request.status;
                    statusBarData.value.title = "Генерация";
                    statusBarData.value.status = imageStatus;
                    if (imageStatus === "done") {
                        if (typeof response.request.screenshot === "string") {
                            imageUrl.value.push(response.request.screenshot);
                        }
                        else {
                            imageUrl.value.push(...response.request.screenshot);
                        }
                        price.value = Number(response.request.price);
                        estimate.value.push(rebuildEstimate(response.request.estimate));
                        console.log(estimate.value);
                        isGenerateImage.value = false;
                        statusBarData.value.title = "";
                        statusBarData.value.status = "";
                        clearInterval(intervalId);
                    }
                };
                const intervalId = setInterval(checkImageStatus, 5000);
            }
        };
        const getResponse = async () => {
            await AK.getCollectionQuestions(props.collectionCode).then((res) => {
                if (!res)
                    return;
                dataResponse.value = res.getResponse().steps;
                console.log("dataResponse", dataResponse.value);
                const test1 = [];
                const test2 = [];
                for (const step of dataResponse.value) {
                    if (!test1.length && !test2.length) {
                        test1.push(step);
                        test2.push({
                            id: step.id,
                            questions: [
                                {
                                    id: step.questions[0].id,
                                    answers: [
                                        {
                                            id: step.questions[0].answers[0].id,
                                        },
                                    ],
                                },
                            ],
                        });
                    }
                    else {
                        if (step.questions.length === 1) {
                            if (step.questions[0].conditions.length &&
                                !step.questions[0].attributes.length) {
                                // for (const condition of step.questions[0].conditions) {
                                //   if (condition.answers && condition.answers.length) {
                                //     console.log("Test", condition.answers[0]);
                                //   }
                                // }
                            }
                            else if (!step.questions[0].conditions.length &&
                                step.questions[0].attributes.length) {
                                test1.push(step);
                                const questions = [
                                    {
                                        id: step.questions[0].id,
                                        answers: [
                                            {
                                                id: step.questions[0].answers[0].id,
                                            },
                                        ],
                                    },
                                ];
                                test2.push({
                                    id: step.id,
                                    questions,
                                });
                                for (const attribute of step.questions[0].attributes) {
                                    if (!attribute.conditions.length) {
                                        questions[0].attributes = [];
                                        questions[0].attributes.push({
                                            id: attribute.id,
                                            selected: false,
                                        });
                                    }
                                }
                            }
                            else if (step.questions[0].conditions.length &&
                                step.questions[0].attributes.length) {
                                const isAttr = test2.find((st) => st.questions.find((que) => que.attributes &&
                                    que.attributes[0].id ===
                                        step.questions[0].conditions[0].attributes[0].id));
                                if (isAttr &&
                                    isAttr.questions[0].attributes &&
                                    isAttr.questions[0].attributes[0].selected) {
                                    test1.push(step);
                                    const questions = [
                                        {
                                            id: step.questions[0].id,
                                            answers: [
                                                {
                                                    id: step.questions[0].answers[0].id,
                                                },
                                            ],
                                        },
                                    ];
                                    test2.push({
                                        id: step.id,
                                        questions,
                                    });
                                    for (const attribute of step.questions[0].attributes) {
                                        if (!attribute.conditions.length) {
                                            questions[0].attributes = [];
                                            questions[0].attributes.push({
                                                id: attribute.id,
                                                selected: false,
                                            });
                                        }
                                    }
                                }
                            }
                            else {
                                test1.push(step);
                                test2.push({
                                    id: step.id,
                                    questions: [
                                        {
                                            id: step.questions[0].id,
                                            answers: [
                                                {
                                                    id: step.questions[0].answers[0].id,
                                                },
                                            ],
                                        },
                                    ],
                                });
                            }
                        }
                        else {
                            const a = step.questions.filter((q) => !q.conditions.length && !q.attributes.length);
                            if (!a.length) {
                                test1.push(step);
                                for (const quest of step.questions) {
                                    if (quest.conditions.length) {
                                        for (const condition of quest.conditions) {
                                            if (condition.answers && condition.answers.length) {
                                                const questCondition = test2.find((item) => item.questions[0].answers &&
                                                    item.questions[0].answers.length > 0 &&
                                                    item.questions[0].answers[0].id ===
                                                        condition.answers[0].id);
                                                if (questCondition) {
                                                    const answer = {
                                                        id: step.id,
                                                        questions: [
                                                            {
                                                                id: quest.id,
                                                                answers: [],
                                                            },
                                                        ],
                                                    };
                                                    for (const an of quest.answers) {
                                                        const a = {
                                                            id: an.id,
                                                        };
                                                        if ("default_value" in an.params) {
                                                            a.value = an.params.default_value;
                                                        }
                                                        answer.questions[0].answers.push(a);
                                                    }
                                                    test2.push(answer);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            else {
                                test1.push(step);
                                const questions = [];
                                for (const quest of step.questions) {
                                    questions.push({
                                        id: quest.id,
                                        answers: [
                                            {
                                                id: quest.answers[0].id,
                                            },
                                        ],
                                    });
                                }
                                test2.push({
                                    id: step.id,
                                    questions,
                                });
                            }
                        }
                    }
                }
                newDataRequest.value = test2;
                // setDataResponse(res.getResponse());
            });
        };
        const getResultAnswers = (event) => {
            if (event) {
                const step = newDataRequest.value.find((item) => item.id === event.id);
                const stepIndex = newDataRequest.value.findIndex((item) => item.id === event.id) + 1;
                if (step) {
                    step.questions = event.questions;
                    if (stepIndex === 2) {
                        const nextStep = newDataRequest.value.find((item) => item.id === stepIndex + 1);
                        const dataStep = dataResponse.value
                            .find((s) => s.id === stepIndex + 1)
                            ?.questions.find((q) => q.conditions[0].answers[0].id === event.questions[0].answers[0].id);
                        console.log("DATASTEP", dataStep);
                        if (dataStep) {
                            const newQ = {
                                id: dataStep.id,
                                answers: [],
                            };
                            for (const answer of dataStep.answers) {
                                if ("default_value" in answer.params)
                                    newQ.answers?.push({
                                        id: answer.id,
                                        value: answer.params.default_value,
                                    });
                            }
                            nextStep?.questions.splice(0, 1, newQ);
                        }
                    }
                }
            }
            // console.log("test", newDataRequest.value);
        };
        const rebuildEstimate = (estimate) => {
            const transformItem = (item) => {
                const { id, type, name, cost, price, count, uom, elements } = item;
                const transformed = {
                    key: id + type || "",
                    type: type || "",
                    label: name || "",
                    cost: cost || 0,
                    price: price || 0,
                    count: count || 0,
                    uom: uom || "",
                };
                if (Array.isArray(elements) && elements.length > 0) {
                    transformed.children = elements.map(transformItem);
                }
                return transformed;
            };
            return transformItem(estimate);
        };
        watch(props, (newProps) => {
            active.value = newProps.activeTestForm;
        });
        watch(active, (newActive) => {
            if (!newActive) {
                emit("setStateForm", newActive);
            }
        });
        return (_ctx, _cache) => {
            const _component_InputNumber = _resolveComponent("InputNumber");
            const _component_Image = _resolveComponent("Image");
            const _component_Galleria = _resolveComponent("Galleria");
            const _component_Avatar = _resolveComponent("Avatar");
            const _component_Tree = _resolveComponent("Tree");
            const _component_Divider = _resolveComponent("Divider");
            const _component_ProgressSpinner = _resolveComponent("ProgressSpinner");
            const _component_Button = _resolveComponent("Button");
            const _component_Dialog = _resolveComponent("Dialog");
            const _component_Toast = _resolveComponent("Toast");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(_component_Dialog, {
                    visible: active.value,
                    "onUpdate:visible": _cache[1] || (_cache[1] = ($event) => ((active).value = $event)),
                    modal: true,
                    pt: {
                        root: {
                            class: 'h-100 w-80 left-auto top-auto',
                        },
                        content: {
                            class: 'pb-3 h-100',
                        },
                        footer: {
                            class: 'pb-3 justify-content-between',
                        },
                    }
                }, {
                    header: _withCtx(() => [
                        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "inline-flex align-items-center justify-content-center gap-2" }, [
                            _createElementVNode("span", { class: "font-bold white-space-nowrap" }, "Тестирование формы опроса ")
                        ], -1)),
                        _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("label", _hoisted_2, "Коллекция: " + _toDisplayString(props.collectionId), 1),
                            _cache[2] || (_cache[2] = _createElementVNode("label", {
                                for: "integeronly",
                                class: "font-bold block mr-2"
                            }, " Введите номер request: ", -1)),
                            _createVNode(_component_InputNumber, {
                                modelValue: requestId.value,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((requestId).value = $event)),
                                inputId: "integeronly"
                            }, null, 8, ["modelValue"])
                        ])
                    ]),
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", _hoisted_4, [
                                (imageUrl.value.length && imageUrl.value.length === 1)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                        _createVNode(_component_Image, {
                                            src: imageUrl.value[0],
                                            alt: "imageUrl[0]",
                                            width: "100%"
                                        }, null, 8, ["src"])
                                    ]))
                                    : (imageUrl.value.length && imageUrl.value.length > 1)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                            _createVNode(_component_Galleria, {
                                                class: "w-full",
                                                value: imageUrl.value,
                                                numVisible: 5,
                                                containerStyle: "max-width: 500px; width: 100%"
                                            }, {
                                                item: _withCtx((slotProps) => [
                                                    _createElementVNode("img", {
                                                        src: slotProps.item,
                                                        alt: slotProps.item,
                                                        style: { "width": "100%" }
                                                    }, null, 8, _hoisted_7)
                                                ]),
                                                thumbnail: _withCtx((slotProps) => [
                                                    _createElementVNode("img", {
                                                        style: { "width": "90%" },
                                                        src: slotProps.item,
                                                        alt: slotProps.item
                                                    }, null, 8, _hoisted_8)
                                                ]),
                                                _: 1
                                            }, 8, ["value"])
                                        ]))
                                        : _createCommentVNode("", true),
                                (estimate.value.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                        _createElementVNode("div", _hoisted_10, [
                                            _createVNode(_component_Tree, {
                                                value: estimate.value,
                                                class: "w-full md:w-30rem text-xs p-0",
                                                pt: {
                                                    content: { class: 'border-2 border-black-alpha-20' },
                                                    container: { class: 'p-0' },
                                                }
                                            }, {
                                                default: _withCtx((slotProps) => [
                                                    _createElementVNode("div", _hoisted_11, [
                                                        _createElementVNode("b", null, _toDisplayString(slotProps.node.label === ""
                                                            ? slotProps.node.type
                                                            : slotProps.node.label), 1),
                                                        _createElementVNode("span", _hoisted_12, _toDisplayString(slotProps.node.label === ""
                                                            ? `count: ${slotProps.node.count} ${slotProps.node.uom}`
                                                            : `count: ${slotProps.node.count} ${slotProps.node.uom}`), 1),
                                                        _createElementVNode("span", _hoisted_13, _toDisplayString(slotProps.node.label === ""
                                                            ? `cost: ${slotProps.node.cost}`
                                                            : `price: ${slotProps.node.price}`), 1),
                                                        _createElementVNode("span", _hoisted_14, _toDisplayString(slotProps.node.label === ""
                                                            ? ""
                                                            : `cost: ${slotProps.node.cost}`), 1)
                                                    ])
                                                ]),
                                                nodeicon: _withCtx((slotProps) => [
                                                    _createElementVNode("div", _hoisted_15, [
                                                        _createVNode(_component_Avatar, {
                                                            shape: "circle",
                                                            icon: `pi ${slotProps.node.children ? 'pi-folder' : 'pi-receipt'}`
                                                        }, null, 8, ["icon"])
                                                    ])
                                                ]),
                                                _: 1
                                            }, 8, ["value"])
                                        ])
                                    ]))
                                    : _createCommentVNode("", true)
                            ]),
                            _createVNode(_component_Divider, {
                                class: "mx-1",
                                layout: "vertical"
                            }),
                            _createElementVNode("div", _hoisted_16, [
                                _createVNode(RequestBlock, {
                                    class: "scroll border-2 border-black-alpha-20 border-radius-md border-bottom-end-radius-0 border-top-end-radius-0",
                                    data: dataResponse.value,
                                    request: newDataRequest.value,
                                    onAnswers: getResultAnswers
                                }, null, 8, ["data", "request"]),
                                _createVNode(_component_Divider, {
                                    class: "mx-1 xl:block sm:hidden md:hidden lg:hidden",
                                    layout: "vertical"
                                }),
                                _createVNode(_component_Divider, {
                                    class: "mx-1 xl:hidden sm:block md:block lg:block",
                                    layout: "horizontal"
                                }),
                                _createVNode(ResponseBlock, {
                                    class: "scroll border-2 border-black-alpha-20 border-radius-md border-bottom-end-radius-0 border-top-end-radius-0",
                                    data: dataResponse.value,
                                    request: newDataRequest.value
                                }, null, 8, ["data", "request"])
                            ]),
                            (isGenerateImage.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                    _createVNode(_component_ProgressSpinner, { class: "h-20 w-20" })
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    footer: _withCtx(() => [
                        _createElementVNode("div", _hoisted_18, [
                            (statusBarData.value.title !== '')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-xs" }, "Request status:", -1)),
                                    _createElementVNode("div", _hoisted_20, _toDisplayString(statusBarData.value.title) + ": " + _toDisplayString(statusBarData.value.status), 1)
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                            _createVNode(_component_Button, {
                                icon: "pi pi-file-export",
                                label: "Тест ответы",
                                class: "p-2 rounded text-sm",
                                onClick: testExportRequest
                            }),
                            _createVNode(_component_Button, {
                                icon: "pi pi-file-export",
                                label: "Отправить ответы",
                                class: "p-2 rounded text-sm",
                                loading: isGenerateImage.value,
                                disabled: !newDataRequest.value.length,
                                onClick: exportRequest
                            }, null, 8, ["loading", "disabled"]),
                            _createVNode(_component_Button, {
                                icon: "pi pi-file-import",
                                loading: isGenerateImage.value,
                                label: "Получить вопросы",
                                class: "p-2 rounded text-sm",
                                onClick: getResponse
                            }, null, 8, ["loading"]),
                            _createVNode(_component_Button, {
                                class: "p-2 rounded text-sm",
                                loading: isGenerateImage.value,
                                rounded: "",
                                label: "Close",
                                onClick: close
                            }, null, 8, ["loading"])
                        ])
                    ]),
                    _: 1
                }, 8, ["visible"]),
                _createVNode(_component_Toast)
            ], 64));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "textStyle"
};
import { computed } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ButtonHideSidbar',
    props: {
        label: {},
        icon: {},
        minimize: { type: Boolean }
    },
    emits: ["click"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const min = computed(() => {
            return props.minimize === undefined ? false : props.minimize;
        });
        function onClick(event) {
            event.stopPropagation(); // Останавливаем всплытие события
            emit("click"); // Эмитим событие для родителя
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["linkActive flex align-items-center", min.value ? 'min' : 'max'])
            }, [
                _createElementVNode("div", {
                    class: "linkContent flex align-items-center cursor-pointer",
                    onClick: onClick
                }, [
                    (_ctx.icon)
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: _normalizeClass(`pi ${_ctx.icon}`)
                        }, null, 2))
                        : _createCommentVNode("", true),
                    (_ctx.label && !min.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
                        : _createCommentVNode("", true)
                ])
            ], 2));
        };
    }
});

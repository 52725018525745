import defaultRequest from "@/api/DefaultRequest";
class WebPlanner {
    constructor() {
        // .
    }
    getCatalogList(instanceId = 0) {
        return defaultRequest.get("web-planner/backend/catalogs" +
            (instanceId > 0 ? "/?instanceId=" + instanceId : ""));
    }
    getInstances() {
        return defaultRequest.get("web-planner/backend/instances");
    }
    getModelStructure(instanceId, modelId, uid) {
        return defaultRequest.post(`tools/models/`, {
            instance: instanceId,
            model_id: modelId,
            uid: uid,
        });
    }
    getRoles() {
        return defaultRequest.get("web-planner/backend/roles");
    }
    testRequest() {
        return defaultRequest.get("test/decode");
    }
    getImportHistory(historyId) {
        return defaultRequest.get("web-planner/backend/imports/histories/" + historyId);
    }
    getQueueState() {
        return defaultRequest.get("web-planner/backend/queues/state");
    }
    //
    syncDatabase(instanceId, catalogId = 0, folderId = 0) {
        if (catalogId === 0) {
            return defaultRequest.get("web-planner/backend/sync-catalog/" + instanceId);
        }
        else {
            return defaultRequest.get(`web-planner/backend/sync-catalog/${instanceId}/` +
                (catalogId < 0 ? "only-parent" : catalogId) +
                (folderId > 0 ? "/" + folderId : ""));
        }
    }
    syncPropertyDatabase(instanceId) {
        return defaultRequest.get("web-planner/backend/sync-properties/" + instanceId);
    }
    syncModelsDatabase(instanceId, catalogId = 0, folderId = 0, modelId = 0) {
        return defaultRequest.get("web-planner/backend/sync-models/" +
            instanceId +
            (catalogId > 0 ? "/" + catalogId : "") +
            (folderId > 0 ? "/" + folderId : "") +
            (modelId > 0 ? "/" + modelId : ""));
    }
    getCatalogHistory(catalogId, instanceId) {
        return defaultRequest.get("web-planner/backend/imports/histories/catalog/" + catalogId.toString());
    }
    getItemDetail(itemId, name, catalogId = 0) {
        return defaultRequest.get("web-planner/backend/item/" +
            itemId.toString() +
            (name.length > 0 ? "?name=" + name + "&catalogId=" + catalogId : ""));
    }
    createInstance(params) {
        return defaultRequest.post("web-planner/backend/instances", params);
    }
    importMaterials(catalogId, params) {
        return defaultRequest.post("web-planner/backend/catalogs/" + catalogId + "/import", params);
    }
    getCatalogProperties(catalogId, instanceId, p = 1, q = "") {
        return defaultRequest.get("web-planner/backend/catalogs/" +
            catalogId +
            "/properties/?instanceId=" +
            instanceId +
            "&p=" +
            p +
            "&q=" +
            q);
    }
    getCatalogProperty(catalogId, propertyId) {
        return defaultRequest.get("web-planner/backend/catalogs/" + catalogId + "/properties/" + propertyId);
    }
    reSyncProperty(catalogId, propertyId) {
        return defaultRequest.post("web-planner/backend/catalogs/" + catalogId + "/properties/" + propertyId, {});
    }
    getCatalogPropertyModels(catalogId, propertyId) {
        return defaultRequest.get("web-planner/backend/catalogs/" +
            catalogId +
            "/properties/" +
            propertyId +
            "/models");
    }
    getCatalogPropertySyncStatus(catalogId, propertyId) {
        return defaultRequest.get("web-planner/backend/catalogs/" +
            catalogId +
            "/properties/" +
            propertyId +
            "/sync-status");
    }
    getCatalogModels(catalogId, parentId, full = true) {
        return defaultRequest.get(`web-planner/backend/catalogs/${catalogId}/models/${parentId}` +
            (full ? "?full=true" : ""));
    }
    getCatalogModel(catalogId, parentId) {
        return defaultRequest.get("web-planner/backend/catalogs/" + catalogId + "/model/" + parentId);
    }
    getCatalogPropertyParams(catalogId = "0") {
        return defaultRequest.get("web-planner/backend/catalogs/property-params/" +
            (catalogId !== "0" ? "?catalogId=" + catalogId : ""));
    }
    updateCatalogProperty(catalogId, propertyId, data) {
        const params = {
            _method: "PATCH",
            catalogId: catalogId,
            propertyId: propertyId,
            data: data,
        };
        return defaultRequest.post("web-planner/backend/catalogs/" + catalogId + "/properties/" + propertyId, params);
    }
    updateModelProperties(catalogId, modelId, data) {
        const params = {
            _method: "PATCH",
            data: data,
        };
        return defaultRequest.post(`web-planner/backend/catalogs/${catalogId}/model/${modelId}/properties`, 
        // "web-planner/backend/catalogs/" + catalogId + "/properties/",
        params);
    }
    createCatalogProperty(catalogId, data) {
        const params = {
            catalogId: catalogId,
            data: data,
        };
        return defaultRequest.post("web-planner/backend/catalogs/" + catalogId + "/properties/", params, true);
    }
    findMaterials(list, catalogId = 0) {
        return defaultRequest.post("web-planner/backend/catalogs/" + catalogId + "/find/materials/", { data: list });
    }
    /**
     *
     * @param catalogId
     * @param folderId
     * @param data
     */
    copyModelFolder(catalogId, folderId, data) {
        return defaultRequest.post(`web-planner/backend/catalogs/${catalogId}/models/${folderId}/copy`, data);
    }
    copyModelFolderStatus(copyId) {
        return defaultRequest.get(`web-planner/backend/copy-folder/${copyId}/status`);
    }
    getDecodeProtobufMessage(message) {
        return defaultRequest.post("web-planner/backend/decode/protobuf", {
            message: message,
        });
    }
    getCatalogDetail(itemId, folderId, page = 0, instanceId = 0, q = "") {
        return defaultRequest.get("web-planner/backend/catalogs/" +
            //  (folderId > 0  ?
            `${itemId}/folders/${folderId}/${page}` +
            //: `${itemId}/${page}`) +
            (q.length > 0 ? "?q=" + q : "") +
            (instanceId > 0
                ? (q.length > 0 ? "&" : "?") + "instanceId=" + instanceId
                : ""));
    }
    setMaterial(itemId, data, removeBump = false) {
        return defaultRequest.post("web-planner/backend/catalogs/items/", {
            id: itemId,
            data: data,
            removeBump: removeBump,
        });
    }
    setFiles(itemId, files) {
        return defaultRequest.post("web-planner/backend/catalogs/items/" + itemId + "/materials", files);
    }
    setBumpFromTexture(itemId) {
        return defaultRequest.post("web-planner/backend/catalogs/items/" + itemId + "/bumpFromTexture", {
            _method: "PATCH",
        });
    }
    setModelPropertyRow(catalogId, modelId, linkId, rowId) {
        return defaultRequest.post("web-planner/backend/catalogs/" + catalogId + "/models/", {
            _method: "PATCH",
            data: {
                modelId: modelId,
                linkId: linkId,
                rowId: rowId,
            },
        });
    }
    deleteMaterial(itemId, catalogId) {
        return defaultRequest.post("web-planner/backend/catalogs/" + catalogId + "/items/" + itemId, {
            _method: "DELETE",
        });
    }
    deleteProperty(itemId, catalogId) {
        return defaultRequest.post("web-planner/backend/catalogs/" + catalogId + "/properties/" + itemId, {
            _method: "DELETE",
        });
    }
    getVariantPropertyTypesValues() {
        return defaultRequest.get(`web-planner/backend/handbooks/properties/typevalues`);
    }
    fixMaterial(itemId, catalogId) {
        return defaultRequest.post("web-planner/backend/fix/removeMaterial/" + catalogId + "/" + itemId, {
            _method: "DELETE",
        });
    }
    getHandbookParams(queryString = "") {
        return defaultRequest.get(`web-planner/backend/handbooks/params${queryString.length > 0 ? "?q=" + queryString : ""}`);
    }
    getAttributes() {
        return defaultRequest.get(`web-planner/backend/handbooks/attributes`);
    }
    setAttribute(data) {
        return defaultRequest.post(`web-planner/backend/handbooks/attributes`, data);
    }
    getPropertyGroups() {
        return defaultRequest.get(`web-planner/backend/handbooks/properties/groups`);
    }
    getPropertyModels() {
        return defaultRequest.get(`web-planner/backend/handbooks/properties/models`);
    }
    setPropertyGroups(data) {
        return defaultRequest.post(`web-planner/backend/handbooks/properties/groups`, data);
    }
    setPropertyModels(data) {
        return defaultRequest.post(`web-planner/backend/handbooks/properties/models`, data);
    }
    getHandbookParam(id) {
        return defaultRequest.get("web-planner/backend/handbooks/params/" + id);
    }
    addHandbookParam(paramName, paramDesc) {
        return defaultRequest.post("web-planner/backend/handbooks/params/", {
            name: paramName,
            description: paramDesc,
        });
    }
    updateHandbookParam(paramId, paramName) {
        return defaultRequest.post("web-planner/backend/handbooks/params/" + paramId, {
            name: paramName,
            _method: "PATCH",
        });
    }
    async getModelIframeLink(catalogId, modelId) {
        return defaultRequest.get(`web-planner/backend/catalogs/${catalogId}/model/${modelId}/embedModelLink`);
    }
    async getParamTypes() {
        return defaultRequest.get("web-planner/backend/handbooks/params/getTypes/");
    }
    async setType(paramId, newType) {
        const formdata = new FormData();
        formdata.append("type", newType);
        return defaultRequest.post(`web-planner/backend/handbooks/params/${paramId}/setType`, formdata);
    }
}
export default new WebPlanner();

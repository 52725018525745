import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue";
const _hoisted_1 = { class: "w-25rem xl:w-30rem h-full flex flex-column gap-2 overflow-y-auto" };
const _hoisted_2 = {
    key: 3,
    class: "flex flex-column h-5rem border-2 border-radius-md border-black-alpha-20 hover:border-yellow-500 mx-2"
};
import HeaderRequestBlock from "@/binaplan/components/autoKitchen/requestBlock/header/HeaderRequestBlock.vue";
import ChecksBlock from "@/binaplan/components/autoKitchen/requestBlock/content/checksBlock.vue";
import RadioBlock from "@/binaplan/components/autoKitchen/requestBlock/content/radioBlock.vue";
import ImageQuestion from "@/binaplan/components/autoKitchen/requestBlock/content/imageQuestion.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'RequestBlock',
    props: {
        data: {},
        request: {}
    },
    emits: ["answers"],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const checkedAnswer = (event) => {
            emit("answers", event);
        };
        return (_ctx, _cache) => {
            const _component_Skeleton = _resolveComponent("Skeleton");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.request, (step) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: step.id
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.questions, (quest, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "flex flex-column"
                            }, [
                                _createVNode(HeaderRequestBlock, {
                                    title: props.data
                                        .find((s) => s.id === step.id)
                                        .questions.find((q) => q.id === quest.id).title,
                                    description: _ctx.data
                                        .find((s) => s.id === step.id)
                                        .questions.find((q) => q.id === quest.id).description,
                                    indexStep: _ctx.request.findIndex((s) => s.id === step.id) + 1,
                                    countSteps: _ctx.request.length,
                                    maxAnswers: _ctx.data
                                        .find((s) => s.id === step.id)
                                        .questions.find((q) => q.id === quest.id).max_answers,
                                    countQuestions: _ctx.data.find((s) => s.id === step.id).questions.length,
                                    selectedAnswers: props.request[0].questions[0].answers.length
                                }, null, 8, ["title", "description", "indexStep", "countSteps", "maxAnswers", "countQuestions", "selectedAnswers"]),
                                (_ctx.data.find((s) => s.id === step.id).template === 'blocks' &&
                                    _ctx.data
                                        .find((s) => s.id === step.id)
                                        .questions.find((q) => q.id === quest.id).type === 'checkbox')
                                    ? (_openBlock(), _createBlock(ChecksBlock, {
                                        key: 0,
                                        data: _ctx.data.find((s) => s.id === step.id),
                                        stepId: step.id,
                                        request: _ctx.request,
                                        onCheckedAnswer: checkedAnswer
                                    }, null, 8, ["data", "stepId", "request"]))
                                    : _createCommentVNode("", true),
                                (_ctx.data.find((s) => s.id === step.id).template === 'blocks' &&
                                    _ctx.data
                                        .find((s) => s.id === step.id)
                                        .questions.find((q) => q.id === quest.id).type === 'radio')
                                    ? (_openBlock(), _createBlock(RadioBlock, {
                                        key: 1,
                                        data: _ctx.data.find((s) => s.id === step.id),
                                        stepId: step.id,
                                        request: _ctx.request,
                                        onCheckedAnswer: checkedAnswer
                                    }, null, 8, ["data", "stepId", "request"]))
                                    : _createCommentVNode("", true),
                                (_ctx.data.find((s) => s.id === step.id).template === 'image_question')
                                    ? (_openBlock(), _createBlock(ImageQuestion, {
                                        key: 2,
                                        data: _ctx.data,
                                        stepId: step.id,
                                        request: _ctx.request,
                                        onCheckedAnswer: checkedAnswer
                                    }, null, 8, ["data", "stepId", "request"]))
                                    : _createCommentVNode("", true),
                                (_ctx.data.find((s) => s.id === step.id).template === 'canvas' ||
                                    _ctx.data.find((s) => s.id === step.id).template === 'list')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                        _createVNode(_component_Skeleton, {
                                            width: "100%",
                                            height: "100%"
                                        })
                                    ]))
                                    : _createCommentVNode("", true)
                            ]));
                        }), 128))
                    ]));
                }), 128))
            ]));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = { class: "flex flex-row flex-wrap gap-2 overflow-y-auto w-full h-max px-2" };
const _hoisted_2 = { class: "w-50 h-full" };
const _hoisted_3 = {
    for: "input",
    class: "font-bold block w-full mb-1 text-xs"
};
import { onMounted, ref, watch } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'imageQuestion',
    props: {
        data: {},
        stepId: {},
        request: {}
    },
    emits: ["checkedAnswer"],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const quest = ref();
        const answers = ref([]);
        const imageZone = ref(null);
        const widthImage = ref(0);
        watch(imageZone, (newImageZone) => {
            if (newImageZone) {
                widthImage.value = newImageZone.offsetWidth;
            }
        });
        watch(props.request, (newData) => {
            if (newData) {
                quest.value = props.data
                    .find((s) => s.id === props.stepId)
                    ?.questions.find((q) => q.id ===
                    props.request.find((s) => {
                        return s.id === props.stepId;
                    })?.questions[0].id);
                if (quest.value) {
                    answers.value = [];
                    for (const answer of quest.value.answers) {
                        const value = props.request
                            .find((s) => s.id === props.stepId)
                            ?.questions[0].answers.find((a) => a.id === answer.id)?.value;
                        if (value)
                            answers.value.push({
                                id: answer.id,
                                params: {
                                    title: answer.params.title,
                                    type: answer.params.type,
                                    default_value: answer.params.default_value,
                                    min_value: answer.params.min_value,
                                    max_value: answer.params.max_value,
                                    value: value,
                                },
                            });
                    }
                }
            }
        });
        const getAnswer = () => {
            const result = {
                id: props.stepId,
                questions: [
                    {
                        id: quest.value.id,
                        answers: [],
                    },
                ],
            };
            for (const answer of answers.value) {
                result.questions[0].answers.push({
                    id: answer.id,
                    value: answer.params.value,
                });
            }
            return result;
            //   const step = props.request.find((step) => step.id === props.data.id);
            //   if (step) {
            //     const newStep: {
            //       id: number;
            //       questions: Array<{
            //         id: number;
            //         answers: Array<{ id: number; value?: number }>;
            //         attributes?: Array<{ id: number; selected: boolean }>;
            //       }>;
            //     } = {
            //       id: step.id,
            //       questions: [],
            //     };
            //     for (const quest of step.questions) {
            //       const newQuest: {
            //         id: number;
            //         answers: Array<{ id: number; value?: number }>;
            //         attributes?: Array<{ id: number; selected: boolean }>;
            //       } = {
            //         id: quest.id,
            //         answers: [],
            //       };
            //
            //       for (const answer of quest.answers) {
            //         const newAnswer: {
            //           id: number;
            //           value?: number;
            //         } = {
            //           id: answer.id,
            //         };
            //         if (answer.value) {
            //           newAnswer.value = answer.value;
            //         }
            //         newQuest.answers.push(newAnswer);
            //       }
            //       newStep.questions.push(newQuest);
            //     }
            //     const questionId = props.data.questions.find((quest) =>
            //       quest.answers.find((answer) => answer.id === id)
            //     )?.id;
            //     if (questionId) {
            //       const questResp = newStep.questions.find(
            //         (quest) => quest.id === questionId
            //       );
            //       if (questResp) {
            //         questResp.answers[0].id = id;
            //       }
            //     }
            //     return newStep;
            //   }
        };
        const initEmit = (data) => {
            emit("checkedAnswer", data);
        };
        onMounted(() => {
            quest.value = props.data
                .find((s) => s.id === props.stepId)
                ?.questions.find((q) => q.id ===
                props.request.find((s) => s.id === props.stepId)?.questions[0].id);
        });
        return (_ctx, _cache) => {
            const _component_Image = _resolveComponent("Image");
            const _component_InputNumber = _resolveComponent("InputNumber");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                    ref_key: "imageZone",
                    ref: imageZone,
                    class: "flex-grow-1 h-max border-2 border-radius-md border-black-alpha-20",
                    style: { "max-width": "45%" }
                }, [
                    _createVNode(_component_Image, {
                        class: "mb-1",
                        ref: "card",
                        src: quest.value?.image,
                        alt: "Image",
                        width: "100%",
                        style: _normalizeStyle(`height: ${widthImage.value}px`)
                    }, null, 8, ["src", "style"])
                ], 512),
                _createElementVNode("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(answers.value, (input) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: input.id
                        }, [
                            _createElementVNode("label", _hoisted_3, _toDisplayString(input.params.title), 1),
                            _createVNode(_component_InputNumber, {
                                modelValue: input.params.value,
                                "onUpdate:modelValue": [
                                    ($event) => ((input.params.value) = $event),
                                    _cache[0] || (_cache[0] = ($event) => (initEmit(getAnswer())))
                                ],
                                inputId: "input",
                                suffix: " мм",
                                max: input.params.max_value,
                                min: input.params.min_value,
                                "input-class": "w-full py-1 px-2 mb-1"
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "max", "min"])
                        ]));
                    }), 128))
                ])
            ]));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeStyle as _normalizeStyle, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-lg-12" };
const _hoisted_3 = { class: "card" };
const _hoisted_4 = { class: "card-body min-body" };
const _hoisted_5 = { class: "w-full flex flex-row" };
const _hoisted_6 = { class: "w-25" };
const _hoisted_7 = { class: "flex-grow-1 p-2" };
const _hoisted_8 = { class: "w-full flex justify-content-end p-2" };
const _hoisted_9 = { class: "w-max" };
const _hoisted_10 = { class: "flex flex-row" };
const _hoisted_11 = { class: "flex flex-wrap gap-2 align-items-center justify-content-between" };
const _hoisted_12 = ["src"];
const _hoisted_13 = { style: { "text-align": "left" } };
const _hoisted_14 = { class: "flex justify-content-end gap-2" };
const _hoisted_15 = ["src", "alt"];
const _hoisted_16 = { class: "field" };
const _hoisted_17 = {
    key: 0,
    class: "p-error"
};
const _hoisted_18 = { class: "field" };
const _hoisted_19 = { class: "field" };
const _hoisted_20 = { key: 0 };
const _hoisted_21 = { key: 1 };
const _hoisted_22 = { key: 2 };
const _hoisted_23 = { class: "formgrid grid" };
const _hoisted_24 = { class: "field col" };
import AutoKitchen from "@/module/AutoKitchen";
import router from "@/binaplan/router";
import { onMounted, ref, watch } from "vue";
import { useSideMainStore } from "@/store/SideMainStore";
import Planner from "@/service/Planner";
import { useAutoKitchenStore } from "@/store/AutoKitchenStore";
import TitlePage from "@/binaplan/components/layouts/TitlePageContainer.vue";
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";
import ArrayHelper from "@/helpers/ArrayHelper";
// Модели
export default /*@__PURE__*/ _defineComponent({
    __name: 'CollectionView',
    setup(__props) {
        const catalog = ref(useAutoKitchenStore().catalog);
        const visibleModal = ref(false);
        const sideMainStore = useSideMainStore();
        const collectionId = ref(Number(router.currentRoute.value.params.id));
        const columns = ref([
            { field: "preview", header: "preview" },
            { field: "shared", header: "Доступ" },
        ]);
        const selectedColumns = ref(columns.value);
        const onToggle = (val) => {
            selectedColumns.value = columns.value.filter((col) => val.includes(col));
        };
        const data = ref();
        onMounted(async () => {
            await loadData();
        });
        watch(() => router.currentRoute.value.params.id ?? 0, async () => {
            collectionId.value = Number(router.currentRoute.value.params.id);
            await loadData();
        });
        const loadData = async () => {
            await AutoKitchen.getCollection(collectionId.value).then((res) => {
                data.value = res.response;
            });
        };
        const deleteCollection = async () => {
            await sideMainStore.delete(collectionId.value);
            router.go(-1);
        };
        const toast = useToast();
        const dt = ref();
        const styles = ref([]);
        const emptyStyle = {
            id: 0,
            title: "",
            subTitle: "",
            price: "",
        };
        const style = ref(emptyStyle);
        const styleDialog = ref(false);
        const deleteStyleDialog = ref(false);
        const deleteStylesDialog = ref(false);
        const selectedStyles = ref();
        const file = ref();
        const onSelectedFiles = (event) => {
            if (event.files.length > 0) {
                console.log(event.files);
                file.value = event.files[0];
                style.value.img = file.value.objectURL ? file.value.objectURL : "";
            }
        };
        const filters = ref({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        const submitted = ref(false);
        const statuses = ref([
            { label: "Доступно", value: "Доступно" },
            { label: "Недоступно", value: "Недоступно" },
        ]);
        const formatCurrency = (value) => {
            if (value)
                return value.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                });
            return;
        };
        const openNew = () => {
            style.value = emptyStyle;
            submitted.value = false;
            styleDialog.value = true;
        };
        const hideDialog = () => {
            styleDialog.value = false;
            submitted.value = false;
        };
        const saveStyle = () => {
            submitted.value = true;
            if (style.value.title.trim()) {
                if (style.value.id > 0) {
                    style.value.inventoryStatus = style.value.inventoryStatus
                        ? style.value.inventoryStatus
                        : style.value.inventoryStatus;
                    styles.value[ArrayHelper.findIndexById(styles.value, style.value.id)] =
                        style.value;
                    toast.add({
                        severity: "success",
                        summary: "Successful",
                        detail: "Style Updated",
                        life: 3000,
                    });
                }
                else {
                    style.value.img = file.value
                        ? file.value
                        : "https://primefaces.org/cdn/primevue/images/product/product-placeholder.svg";
                    style.value.inventoryStatus = style.value.inventoryStatus
                        ? style.value.inventoryStatus.value
                        : "Доступно";
                    console.log("FILE:", file.value);
                    AutoKitchen.createStyle(collectionId.value, style.value, file.value).then((res) => {
                        if (res.isSuccess()) {
                            useAutoKitchenStore().addStyle(style.value);
                            toast.add({
                                severity: "success",
                                summary: "Successful",
                                detail: "Style Created",
                                life: 3000,
                            });
                        }
                        else {
                            toast.add({
                                severity: "error",
                                summary: "Error",
                                detail: "Error style created",
                                life: 3000,
                            });
                        }
                    });
                }
                styleDialog.value = false;
                style.value = emptyStyle;
            }
        };
        const editStyle = (prod) => {
            style.value = { ...prod };
            styleDialog.value = true;
        };
        const confirmDeleteStyle = (prod) => {
            style.value = prod;
            deleteStyleDialog.value = true;
            deleteStyle();
        };
        const deleteStyle = () => {
            useAutoKitchenStore().removeStyle(style.value);
            styles.value = useAutoKitchenStore().getStyles();
            deleteStyleDialog.value = false;
            style.value = {};
            toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Style Deleted",
                life: 3000,
            });
        };
        const exportCSV = () => {
            dt.value.exportCSV();
        };
        const confirmDeleteSelected = () => {
            deleteStylesDialog.value = true;
        };
        const deleteSelectedStyles = () => {
            styles.value = styles.value.filter((val) => !selectedStyles.value.includes(val));
            deleteStylesDialog.value = false;
            selectedStyles.value = null;
            toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Styles Deleted",
                life: 3000,
            });
        };
        const getStatusLabel = (status) => {
            switch (status) {
                case "Доступно":
                    return "success";
                case "Недоступно":
                    return "danger";
                default:
                    return undefined;
            }
        };
        onMounted(() => {
            styles.value = useAutoKitchenStore().getStyles();
        });
        // Стили
        return (_ctx, _cache) => {
            const _component_Button = _resolveComponent("Button");
            const _component_AccordionTab = _resolveComponent("AccordionTab");
            const _component_Accordion = _resolveComponent("Accordion");
            const _component_TabPanel = _resolveComponent("TabPanel");
            const _component_Toolbar = _resolveComponent("Toolbar");
            const _component_InputIcon = _resolveComponent("InputIcon");
            const _component_InputText = _resolveComponent("InputText");
            const _component_IconField = _resolveComponent("IconField");
            const _component_Column = _resolveComponent("Column");
            const _component_Tag = _resolveComponent("Tag");
            const _component_DataTable = _resolveComponent("DataTable");
            const _component_TabView = _resolveComponent("TabView");
            const _component_MultiSelect = _resolveComponent("MultiSelect");
            const _component_TreeTable = _resolveComponent("TreeTable");
            const _component_Dialog = _resolveComponent("Dialog");
            const _component_FileUpload = _resolveComponent("FileUpload");
            const _component_Textarea = _resolveComponent("Textarea");
            const _component_Dropdown = _resolveComponent("Dropdown");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(TitlePage, {
                    "title-page": data.value?.name
                }, null, 8, ["title-page"]),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_TabView, { scrollable: true }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_TabPanel, { header: "Модели" }, {
                                        default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_5, [
                                                _createElementVNode("div", _hoisted_6, [
                                                    _createVNode(_component_Button, {
                                                        class: "w-full",
                                                        label: "Добавить",
                                                        onClick: _cache[0] || (_cache[0] = ($event) => (visibleModal.value = true))
                                                    }),
                                                    _createVNode(_component_Accordion, { multiple: true }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_AccordionTab, { header: "Нераспределённые" }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 45"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 0"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 55"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 25"
                                                                    })
                                                                ]),
                                                                _: 1
                                                            }),
                                                            _createVNode(_component_AccordionTab, { header: "Нижние" }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 1"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 2"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 3"
                                                                    })
                                                                ]),
                                                                _: 1
                                                            }),
                                                            _createVNode(_component_AccordionTab, { header: "Верхние" }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 4"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 5"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 6"
                                                                    })
                                                                ]),
                                                                _: 1
                                                            }),
                                                            _createVNode(_component_AccordionTab, { header: "Угловые" }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 7"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 8"
                                                                    })
                                                                ]),
                                                                _: 1
                                                            }),
                                                            _createVNode(_component_AccordionTab, { header: "Пеналы" }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 9"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 10"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 11"
                                                                    }),
                                                                    _createVNode(_component_Button, {
                                                                        class: "w-full",
                                                                        label: "Модуль 12"
                                                                    })
                                                                ]),
                                                                _: 1
                                                            })
                                                        ]),
                                                        _: 1
                                                    })
                                                ]),
                                                _createElementVNode("div", _hoisted_7, [
                                                    _createElementVNode("span", null, _toDisplayString(data.value?.name), 1)
                                                ])
                                            ]),
                                            _createElementVNode("div", _hoisted_8, [
                                                _createElementVNode("div", _hoisted_9, [
                                                    _createVNode(_component_Button, {
                                                        class: "w-max",
                                                        label: "Удалить",
                                                        onClick: deleteCollection
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_TabPanel, { header: "Стили" }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_Toolbar, { class: "mb-4" }, {
                                                start: _withCtx(() => [
                                                    _createVNode(_component_Button, {
                                                        label: "Создать",
                                                        icon: "pi pi-plus",
                                                        severity: "success",
                                                        class: "mr-2",
                                                        onClick: openNew
                                                    }),
                                                    _createVNode(_component_Button, {
                                                        label: "Удалить",
                                                        icon: "pi pi-trash",
                                                        severity: "danger",
                                                        onClick: confirmDeleteSelected,
                                                        disabled: !selectedStyles.value || !selectedStyles.value.length
                                                    }, null, 8, ["disabled"])
                                                ]),
                                                _: 1
                                            }),
                                            _createElementVNode("div", _hoisted_10, [
                                                _createVNode(_component_DataTable, {
                                                    ref_key: "dt",
                                                    ref: dt,
                                                    value: styles.value,
                                                    selection: selectedStyles.value,
                                                    "onUpdate:selection": _cache[2] || (_cache[2] = ($event) => ((selectedStyles).value = $event)),
                                                    dataKey: "id",
                                                    filters: filters.value,
                                                    scrollable: "",
                                                    scrollHeight: "550px"
                                                }, {
                                                    header: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_11, [
                                                            _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "m-0" }, "Управление стилями", -1)),
                                                            _createVNode(_component_IconField, { iconPosition: "left" }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_InputIcon, null, {
                                                                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                                                                            _createElementVNode("i", { class: "pi pi-search" }, null, -1)
                                                                        ])),
                                                                        _: 1
                                                                    }),
                                                                    _createVNode(_component_InputText, {
                                                                        modelValue: filters.value['global'].value,
                                                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((filters.value['global'].value) = $event)),
                                                                        placeholder: "Поиск..."
                                                                    }, null, 8, ["modelValue"])
                                                                ]),
                                                                _: 1
                                                            })
                                                        ])
                                                    ]),
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_Column, {
                                                            selectionMode: "multiple",
                                                            style: { "width": "2rem" },
                                                            exportable: false
                                                        }),
                                                        _createVNode(_component_Column, {
                                                            field: "title",
                                                            header: "Name",
                                                            sortable: "",
                                                            style: { "min-width": "10rem" }
                                                        }),
                                                        _createVNode(_component_Column, { header: "Image" }, {
                                                            body: _withCtx((slotProps) => [
                                                                _createElementVNode("img", {
                                                                    src: slotProps.data.img,
                                                                    alt: "test",
                                                                    class: "border-round",
                                                                    style: { "width": "48px" }
                                                                }, null, 8, _hoisted_12)
                                                            ]),
                                                            _: 1
                                                        }),
                                                        _createVNode(_component_Column, {
                                                            field: "subTitle",
                                                            header: "Description",
                                                            sortable: "",
                                                            style: { "min-width": "12rem" }
                                                        }),
                                                        _createVNode(_component_Column, {
                                                            field: "price",
                                                            header: "Price",
                                                            sortable: "",
                                                            style: { "min-width": "8rem" }
                                                        }, {
                                                            body: _withCtx((slotProps) => [
                                                                _createTextVNode(_toDisplayString(formatCurrency(slotProps.data.price)), 1)
                                                            ]),
                                                            _: 1
                                                        }),
                                                        _createVNode(_component_Column, {
                                                            field: "inventoryStatus",
                                                            header: "Status",
                                                            sortable: "",
                                                            style: { "min-width": "8rem" }
                                                        }, {
                                                            body: _withCtx((slotProps) => [
                                                                _createVNode(_component_Tag, {
                                                                    value: slotProps.data.inventoryStatus,
                                                                    severity: getStatusLabel(slotProps.data.inventoryStatus)
                                                                }, null, 8, ["value", "severity"])
                                                            ]),
                                                            _: 1
                                                        }),
                                                        _createVNode(_component_Column, {
                                                            exportable: false,
                                                            style: { "min-width": "8rem" }
                                                        }, {
                                                            body: _withCtx((slotProps) => [
                                                                _createVNode(_component_Button, {
                                                                    icon: "pi pi-pencil",
                                                                    outlined: "",
                                                                    rounded: "",
                                                                    class: "mr-2",
                                                                    onClick: ($event) => (editStyle(slotProps.data))
                                                                }, null, 8, ["onClick"]),
                                                                _createVNode(_component_Button, {
                                                                    icon: "pi pi-trash",
                                                                    outlined: "",
                                                                    rounded: "",
                                                                    severity: "danger",
                                                                    onClick: ($event) => (confirmDeleteStyle(slotProps.data))
                                                                }, null, 8, ["onClick"])
                                                            ]),
                                                            _: 1
                                                        })
                                                    ]),
                                                    _: 1
                                                }, 8, ["value", "selection", "filters"]),
                                                _cache[13] || (_cache[13] = _createElementVNode("div", null, "Поля вариантных свойств", -1))
                                            ])
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_TabPanel, { header: "Наполнение" })
                                ]),
                                _: 1
                            })
                        ])
                    ]),
                    _createVNode(_component_Dialog, {
                        visible: visibleModal.value,
                        "onUpdate:visible": _cache[5] || (_cache[5] = ($event) => ((visibleModal).value = $event)),
                        modal: "",
                        header: "Добавить модель",
                        style: { width: '50rem' }
                    }, {
                        default: _withCtx(() => [
                            _cache[14] || (_cache[14] = _createElementVNode("span", { class: "p-text-secondary block mb-5" }, "Выберите модель/и", -1)),
                            _createVNode(_component_TabView, { scrollable: false }, {
                                default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(catalog.value, (tab) => {
                                        return (_openBlock(), _createBlock(_component_TabPanel, {
                                            key: tab.key,
                                            header: tab.data.catalog
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_TreeTable, {
                                                    value: catalog.value
                                                        .filter((item) => item.data.catalog === tab.data.catalog)[0]
                                                        .children.filter((item) => item.data.name !== '' && item.data.name !== undefined),
                                                    scrollable: "",
                                                    scrollHeight: "300px",
                                                    scrollDirection: "both"
                                                }, {
                                                    header: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_13, [
                                                            _createVNode(_component_MultiSelect, {
                                                                modelValue: selectedColumns.value,
                                                                "onUpdate:modelValue": onToggle,
                                                                options: columns.value,
                                                                optionLabel: "header",
                                                                class: "w-full sm:w-16rem",
                                                                display: "chip"
                                                            }, null, 8, ["modelValue", "options"])
                                                        ])
                                                    ]),
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_Column, {
                                                            field: "name",
                                                            header: "Наименование",
                                                            expander: true,
                                                            style: { "width": "300px" }
                                                        }),
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedColumns.value, (col) => {
                                                            return (_openBlock(), _createBlock(_component_Column, {
                                                                field: col.field,
                                                                header: col.header,
                                                                key: col.field,
                                                                style: { "width": "150px" }
                                                            }, _createSlots({ _: 2 }, [
                                                                (col.field === 'preview')
                                                                    ? {
                                                                        name: "body",
                                                                        fn: _withCtx((slotProps) => [
                                                                            _createElementVNode("div", {
                                                                                style: _normalizeStyle([{ "width": "64px", "height": "64px" }, _unref(Planner).preview(slotProps.node.data.preview, 4)]),
                                                                                class: "texture-image w-full"
                                                                            }, null, 4)
                                                                        ]),
                                                                        key: "0"
                                                                    }
                                                                    : undefined
                                                            ]), 1032, ["field", "header"]));
                                                        }), 128))
                                                    ]),
                                                    _: 2
                                                }, 1032, ["value"])
                                            ]),
                                            _: 2
                                        }, 1032, ["header"]));
                                    }), 128))
                                ]),
                                _: 1
                            }),
                            _createElementVNode("div", _hoisted_14, [
                                _createVNode(_component_Button, {
                                    type: "button",
                                    label: "Cancel",
                                    severity: "secondary",
                                    onClick: _cache[3] || (_cache[3] = ($event) => (visibleModal.value = false))
                                }),
                                _createVNode(_component_Button, {
                                    type: "button",
                                    label: "Save",
                                    onClick: _cache[4] || (_cache[4] = ($event) => (visibleModal.value = false))
                                })
                            ])
                        ]),
                        _: 1
                    }, 8, ["visible"]),
                    _createVNode(_component_Dialog, {
                        visible: styleDialog.value,
                        "onUpdate:visible": _cache[10] || (_cache[10] = ($event) => ((styleDialog).value = $event)),
                        style: { width: '450px' },
                        header: "Детали стиля",
                        modal: true,
                        class: "p-fluid"
                    }, {
                        footer: _withCtx(() => [
                            _createVNode(_component_Button, {
                                label: "Отмена",
                                icon: "pi pi-times",
                                text: "",
                                onClick: hideDialog
                            }),
                            _createVNode(_component_Button, {
                                label: "Сохранить",
                                icon: "pi pi-check",
                                text: "",
                                onClick: saveStyle
                            })
                        ]),
                        default: _withCtx(() => [
                            (!style.value.img)
                                ? (_openBlock(), _createBlock(_component_FileUpload, {
                                    key: 0,
                                    mode: "basic",
                                    accept: "image/*",
                                    maxFileSize: 1000000,
                                    label: "Загрузить изображение",
                                    chooseLabel: "Загрузить изображение",
                                    class: "mr-2 mb-2 inline-block",
                                    onSelect: onSelectedFiles
                                }))
                                : _createCommentVNode("", true),
                            (style.value.img)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 1,
                                    src: style.value.img,
                                    alt: style.value.title,
                                    class: "block m-auto pb-3 w-full"
                                }, null, 8, _hoisted_15))
                                : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_16, [
                                _cache[15] || (_cache[15] = _createElementVNode("label", { for: "name" }, "Наименование", -1)),
                                _createVNode(_component_InputText, {
                                    id: "name",
                                    modelValue: style.value.title,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((style.value.title) = $event)),
                                    modelModifiers: { trim: true },
                                    required: "true",
                                    autofocus: "",
                                    invalid: submitted.value && !style.value.title
                                }, null, 8, ["modelValue", "invalid"]),
                                (submitted.value && !style.value.title)
                                    ? (_openBlock(), _createElementBlock("small", _hoisted_17, "Name is required."))
                                    : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                                _cache[16] || (_cache[16] = _createElementVNode("label", { for: "description" }, "Описание", -1)),
                                _createVNode(_component_Textarea, {
                                    id: "description",
                                    modelValue: style.value.subTitle,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((style.value.subTitle) = $event)),
                                    required: "true",
                                    rows: "3",
                                    cols: "20"
                                }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_19, [
                                _cache[17] || (_cache[17] = _createElementVNode("label", {
                                    for: "inventoryStatus",
                                    class: "mb-3"
                                }, "Статус", -1)),
                                _createVNode(_component_Dropdown, {
                                    id: "inventoryStatus",
                                    modelValue: style.value.inventoryStatus,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((style.value.inventoryStatus) = $event)),
                                    options: statuses.value,
                                    optionLabel: "label",
                                    placeholder: "Выберите статус"
                                }, {
                                    value: _withCtx((slotProps) => [
                                        (slotProps.value && slotProps.value.value)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                                _createVNode(_component_Tag, {
                                                    value: slotProps.value.value,
                                                    severity: getStatusLabel(slotProps.value.label)
                                                }, null, 8, ["value", "severity"])
                                            ]))
                                            : (slotProps.value && !slotProps.value.value)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                                    _createVNode(_component_Tag, {
                                                        value: slotProps.value,
                                                        severity: getStatusLabel(slotProps.value)
                                                    }, null, 8, ["value", "severity"])
                                                ]))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(slotProps.placeholder), 1))
                                    ]),
                                    _: 1
                                }, 8, ["modelValue", "options"])
                            ]),
                            _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("div", _hoisted_24, [
                                    _cache[18] || (_cache[18] = _createElementVNode("label", { for: "price" }, "Цена", -1)),
                                    _createVNode(_component_InputText, {
                                        id: "price",
                                        modelValue: style.value.price,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((style.value.price) = $event)),
                                        mode: "currency",
                                        currency: "USD",
                                        locale: "en-US"
                                    }, null, 8, ["modelValue"])
                                ])
                            ])
                        ]),
                        _: 1
                    }, 8, ["visible"])
                ])
            ]));
        };
    }
});

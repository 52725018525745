import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = {
    class: "flex flex-column relative justify-content-start w-30 h-max p-1 border-2 border-radius-md border-black-alpha-20 hover:border-yellow-500",
    style: { "max-width": "30%" }
};
const _hoisted_2 = { class: "flex flex-column h-5rem" };
const _hoisted_3 = { class: "pt-1 text-sm font-semibold" };
const _hoisted_4 = { class: "pt-1 text-xs" };
const _hoisted_5 = {
    key: 0,
    class: "pt-1 text-xs font-semibold"
};
import { ref, watch } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'CardAnswer',
    props: {
        answer: {}
    },
    setup(__props) {
        const card = ref(null);
        const widthCard = ref(0);
        watch(card, (newCard) => {
            if (newCard) {
                widthCard.value = newCard.$el.offsetWidth;
            }
        });
        return (_ctx, _cache) => {
            const _component_Image = _resolveComponent("Image");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_Image, {
                    class: "mb-1",
                    ref_key: "card",
                    ref: card,
                    src: _ctx.answer.params.image,
                    alt: "Image",
                    width: "100%",
                    style: _normalizeStyle(`height: ${widthCard.value}px`)
                }, null, 8, ["src", "style"]),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.answer.params.title), 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.answer.params.description), 1),
                    (_ctx.answer.params.price)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "от " + _toDisplayString(_ctx.answer.params.price) + " ₽/м.п.", 1))
                        : _createCommentVNode("", true)
                ]),
                _renderSlot(_ctx.$slots, "check"),
                _renderSlot(_ctx.$slots, "radio")
            ]));
        };
    }
});

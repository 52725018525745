import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "card h-full overflow-hidden" };
const _hoisted_2 = { class: "flex flex-column" };
const _hoisted_3 = ["onClick"];
import PlannerBackend from "@/module/WebPlanner";
import Planner from "@/service/Planner";
import { nextTick, onMounted, onUnmounted, onUpdated, ref, watch, } from "vue";
import User from "@/module/User";
import CardModel from "@/binaplan/views/auto-kitchens/catalogs/CardModel.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'CatalogsListViewNew',
    setup(__props) {
        const userInstanceId = User.getMe().instanceId;
        const list = ref(null);
        const isLoading = ref(false);
        const totalRecords = ref(0);
        const selectedKeys = ref({});
        const selectedNode = ref(null);
        const valueSelectNode = ref(null);
        const tabView = ref(null);
        const activeIndex = ref(0);
        const shouldScroll = ref(false);
        const catalogs = ref([]);
        async function updateList() {
            let list = [];
            await PlannerBackend.getCatalogList(userInstanceId).then(async (res) => {
                if (res !== null) {
                    if (res.getType() === "success") {
                        list = res.getResponse().list || [];
                        if (typeof res.getResponse().queues !== "undefined") {
                            Planner.startObserve(res.getResponse().queues.items);
                        }
                    }
                }
            });
            if (list) {
                for (const item of list) {
                    catalogs.value.push({
                        key: item.externalId,
                        data: {
                            catalog: item.name,
                            externalId: item.externalId,
                            name: item.name,
                            shared: item.shared,
                            preview: item.preview,
                        },
                        children: [],
                    });
                }
            }
        }
        async function updateDataCatalog(catalog) {
            isLoading.value = true;
            await PlannerBackend.getCatalogModels(catalog.data.externalId.toString(), "0", false).then((res) => {
                if (res == null)
                    return;
                catalog.children = [];
                const data = res.getResponse().children;
                for (const item of data) {
                    const newItem = {
                        key: item.id,
                        data: item,
                        leaf: item.model,
                    };
                    // Добавляем children: [], если item.model не равен true
                    if (!item.model) {
                        newItem.children = [];
                    }
                    catalog.children.push(newItem);
                }
            });
            isLoading.value = false;
        }
        /*
         * Переопределяю логику выделения строки
         * */
        const selectNode = async (node, catalogId) => {
            if (node.data.model) {
                if (selectedKeys.value[node.key]) {
                    // Если уже выделен, снимаем выделение
                    delete selectedKeys.value[node.key];
                    selectedKeys.value = { ...selectedKeys.value };
                    selectedNode.value = null;
                    valueSelectNode.value = null;
                }
                else {
                    valueSelectNode.value = node;
                    // Если не выделен, выделяем
                    selectedKeys.value = { [node.key]: true };
                    // selectedNode.value = node;
                    console.log(node, catalogId);
                    await PlannerBackend.getCatalogModel(catalogId.toString(), node.key.toString()).then((res) => {
                        if (res == null)
                            return;
                        selectedNode.value = res.getResponse();
                    });
                }
            }
        };
        const unSelectNode = async (node) => {
            // console.log(node);
            delete selectedKeys.value[node.key];
            selectedKeys.value = { ...selectedKeys.value };
            selectedNode.value = null;
        };
        watch(activeIndex, async (newIndex) => {
            await updateDataCatalog(catalogs.value[newIndex]);
        });
        //Определяю разиер табов, если табы не помещаются в размер контейнера, то включаю скролл
        const checkScrollable = () => {
            nextTick(() => {
                const tabViewEl = tabView.value?.$el;
                if (tabViewEl) {
                    const container = tabViewEl.querySelector(".p-tabview-nav"); // Нахожу контейнер вкладок
                    const parent = tabViewEl; // Нахожу родительский контейнер
                    if (container && parent)
                        shouldScroll.value = container.scrollWidth > parent.clientWidth;
                }
            });
        };
        const onResize = () => {
            checkScrollable();
        };
        const onExpand = async (node, catalogId) => {
            isLoading.value = true;
            await PlannerBackend.getCatalogModels(catalogId.toString(), node.data.id, false).then((res) => {
                if (res == null)
                    return;
                console.log(res.getResponse());
                node.children = [];
                const data = res.getResponse().children;
                for (const item of data) {
                    node.children.push({
                        key: item.id,
                        data: item,
                        leaf: item.model,
                    });
                }
            });
            isLoading.value = false;
        };
        onMounted(async () => {
            await updateList();
            await updateDataCatalog(catalogs.value[activeIndex.value]);
            checkScrollable();
            window.addEventListener("resize", onResize);
        });
        onUnmounted(() => {
            window.removeEventListener("resize", onResize);
        });
        onUpdated(() => {
            checkScrollable();
        });
        return (_ctx, _cache) => {
            const _component_Column = _resolveComponent("Column");
            const _component_TreeTable = _resolveComponent("TreeTable");
            const _component_TabPanel = _resolveComponent("TabPanel");
            const _component_TabView = _resolveComponent("TabView");
            const _component_SplitterPanel = _resolveComponent("SplitterPanel");
            const _component_Splitter = _resolveComponent("Splitter");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_Splitter, { style: { "height": "100%" } }, {
                    default: _withCtx(() => [
                        _createVNode(_component_SplitterPanel, null, {
                            default: _withCtx(() => [
                                (catalogs.value.length > 0)
                                    ? (_openBlock(), _createBlock(_component_TabView, {
                                        key: 0,
                                        ref_key: "tabView",
                                        ref: tabView,
                                        activeIndex: activeIndex.value,
                                        "onUpdate:activeIndex": _cache[2] || (_cache[2] = ($event) => ((activeIndex).value = $event)),
                                        scrollable: shouldScroll.value,
                                        pt: {
                                            root: {
                                                class: 'min-h-0 h-full flex flex-column',
                                            },
                                            panelContainer: {
                                                class: 'min-h-0 h-full overflow-y-auto',
                                            },
                                        }
                                    }, {
                                        default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(catalogs.value, (catalog) => {
                                                return (_openBlock(), _createBlock(_component_TabPanel, {
                                                    key: catalog.data.externalId,
                                                    header: catalog.data.name,
                                                    scrollable: true
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_2, [
                                                            _createVNode(_component_TreeTable, {
                                                                value: catalog.children,
                                                                lazy: true,
                                                                loading: isLoading.value,
                                                                selectionKeys: selectedKeys.value,
                                                                "onUpdate:selectionKeys": _cache[1] || (_cache[1] = ($event) => ((selectedKeys).value = $event)),
                                                                selectionMode: "single",
                                                                onNodeExpand: ($event) => (onExpand($event, catalog.data.externalId)),
                                                                totalRecords: totalRecords.value
                                                            }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_Column, {
                                                                        field: "id",
                                                                        header: "ID",
                                                                        class: "w-7rem pr-0 align-items-center",
                                                                        pt: {
                                                                            bodyCell: {
                                                                                class: 'flex',
                                                                            },
                                                                            bodyCellContent: {
                                                                                class: 'flex flex-grow-1 justify-content-end',
                                                                            },
                                                                        },
                                                                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["stop"])),
                                                                        expander: ""
                                                                    }),
                                                                    _createVNode(_component_Column, {
                                                                        field: "name",
                                                                        header: "Name",
                                                                        style: { "padding-left": "0.438rem" }
                                                                    }, {
                                                                        body: _withCtx(({ node }) => [
                                                                            _createElementVNode("div", {
                                                                                class: "flex gap-2",
                                                                                onClick: _withModifiers(($event) => (node?.model ? null : selectNode(node, catalog.key)), ["stop"])
                                                                            }, [
                                                                                _createElementVNode("i", {
                                                                                    class: _normalizeClass(["pi flex align-items-center justify-content-center", !node?.data.model ? 'pi-folder' : 'pi-box'])
                                                                                }, null, 2),
                                                                                _createElementVNode("span", null, _toDisplayString(node?.data.name), 1)
                                                                            ], 8, _hoisted_3)
                                                                        ]),
                                                                        _: 2
                                                                    }, 1024)
                                                                ]),
                                                                _: 2
                                                            }, 1032, ["value", "loading", "selectionKeys", "onNodeExpand", "totalRecords"])
                                                        ])
                                                    ]),
                                                    _: 2
                                                }, 1032, ["header"]));
                                            }), 128))
                                        ]),
                                        _: 1
                                    }, 8, ["activeIndex", "scrollable"]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        }),
                        (selectedNode.value)
                            ? (_openBlock(), _createBlock(_component_SplitterPanel, { key: 0 }, {
                                default: _withCtx(() => [
                                    _createVNode(CardModel, {
                                        data: selectedNode.value,
                                        onCloseModel: _cache[3] || (_cache[3] = ($event) => (unSelectNode(valueSelectNode.value)))
                                    }, null, 8, ["data"])
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                })
            ]));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ButtonCustom',
    props: {
        icon: {
            type: String,
        },
        iconColor: {
            type: String,
            default: "#000000",
            validator: (value) => {
                return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value);
            },
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        roundedCircle: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["click"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const handleClick = () => {
            emit("click"); // Уведомляем родительский компонент
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["header-btn flex align-items-center justify-content-center cursor-pointer", [{ rounded: __props.rounded }, { 'rounded-circle': __props.roundedCircle }]]),
                onClick: handleClick
            }, [
                _createElementVNode("i", {
                    class: _normalizeClass(["pi", __props.icon]),
                    style: _normalizeStyle({ color: __props.iconColor })
                }, null, 6)
            ], 2));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "textColor font-medium" };
const _hoisted_2 = { class: "h-full w-max flex align-items-center justify-content-center gap-1" };
const _hoisted_3 = { class: "flex flex-column pl-3" };
const _hoisted_4 = { class: "font-semibold text-sm textColorSecondary" };
const _hoisted_5 = { class: "font-medium text-sm textColor" };
const _hoisted_6 = { class: "font-medium text-sm ml-2 textColor" };
import User from "@/module/User";
import { ref } from "vue";
import Router from "@/binaplan/router";
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccountMenu',
    setup(__props) {
        const user = ref(User.getMe());
        const userName = ref(user.value.name);
        const userAvatar = ref(user.value.avatar ? user.value.avatar : "/assets/icons/avatar.png");
        console.log("user", user.value);
        const menu = ref();
        const menuAction = ref(false);
        const items = ref([
            {
                separator: true,
            },
            {
                label: "Настройки",
                icon: "pi pi-sliders-h",
                command: () => {
                    Router.push("/profile/setting");
                },
            },
            {
                separator: true,
            },
            {
                label: "Выйти",
                icon: "pi pi-sign-out",
                command: () => {
                    Router.push("/logout");
                },
            },
        ]);
        const toggle = (event) => {
            menu.value.toggle(event);
            menuAction.value = menu.value.overlayVisible;
        };
        return (_ctx, _cache) => {
            const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
            const _component_avatar = _resolveComponent("avatar");
            const _component_Menu = _resolveComponent("Menu");
            const _directive_ripple = _resolveDirective("ripple");
            return (_openBlock(), _createElementBlock("div", {
                class: "user-select-none wrap-container flex align-items-center justify-content-end",
                onClick: toggle,
                "aria-haspopup": "true",
                "aria-controls": "overlay_menu"
            }, [
                (user.value.instanceProd)
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        icon: "fa-solid fa-triangle-exclamation",
                        title: "Вы используете продуктивную версию",
                        "data-bs-toggle": "tooltip",
                        "data-bs-placement": "top",
                        ref: "tooltip",
                        style: { "color": "#cd4e1e" }
                    }, null, 512))
                    : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_1, _toDisplayString(userName.value), 1),
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_avatar, {
                        image: userAvatar.value,
                        rounded: ""
                    }, null, 8, ["image"]),
                    _createElementVNode("i", {
                        style: { "font-size": "0.875rem", "color": "#47475a", "width": "32px" },
                        class: _normalizeClass(["pi h-full flex align-items-center justify-content-center", `pi-angle-${menuAction.value ? 'up' : 'down'}`])
                    }, null, 2)
                ]),
                _createVNode(_component_Menu, {
                    ref_key: "menu",
                    ref: menu,
                    id: "overlay_menu",
                    model: items.value,
                    popup: "",
                    pt: {
                        menu: {
                            style: 'padding-left: 0; margin-bottom: 0;',
                        },
                    }
                }, {
                    start: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(user.value.email), 1),
                            _createElementVNode("span", _hoisted_5, "ID:" + _toDisplayString(user.value.id), 1)
                        ])
                    ]),
                    item: _withCtx(({ item, props }) => [
                        _withDirectives((_openBlock(), _createElementBlock("a", _mergeProps({
                            style: { "height": "28px" },
                            class: "flex align-items-center py-0"
                        }, props.action), [
                            _createElementVNode("span", {
                                class: _normalizeClass(["inputColor", item.icon])
                            }, null, 2),
                            _createElementVNode("span", _hoisted_6, _toDisplayString(item.label), 1)
                        ], 16)), [
                            [_directive_ripple]
                        ])
                    ]),
                    _: 1
                }, 8, ["model"])
            ]));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "w-25rem xl:w-max p-2 overflow-y-auto h-full flex flex-column" };
import ItemResponse from "@/binaplan/components/autoKitchen/ItemResponse.vue";
import { watch } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponseBlock',
    props: {
        data: {},
        request: {}
    },
    setup(__props) {
        const props = __props;
        const emptyData = [
            { id: 0, name: "", response: "" },
            { id: 1, name: "", response: "" },
            { id: 2, name: "", response: "" },
            { id: 3, name: "", response: "" },
            { id: 4, name: "", response: "" },
            { id: 5, name: "", response: "" },
            { id: 6, name: "", response: "" },
            { id: 7, name: "", response: "" },
            { id: 8, name: "", response: "" },
            { id: 9, name: "", response: "" },
            { id: 10, name: "", response: "" },
            { id: 11, name: "", response: "" },
            { id: 12, name: "", response: "" },
            { id: 13, name: "", response: "" },
            { id: 14, name: "", response: "" },
        ];
        const getTitle = (id) => {
            const step = props.data.find((step) => step.id === id);
            if (step) {
                return step.name;
            }
            return "";
        };
        const getAnswer = (id) => {
            const resultArray = [];
            const stepResponse = props.data.find((step) => step.id === id);
            const stepRequest = props.request.find((step) => step.id === id);
            if (!stepRequest || !stepResponse)
                return "";
            for (const quest of stepRequest.questions) {
                const questId = quest.id;
                const answerId = quest.answers[0].id;
                const answerResponse = stepResponse.questions
                    .find((quest) => quest.id === questId)
                    ?.answers.find((answer) => answer.id === answerId);
                let answerValue = 0;
                for (const an of quest.answers) {
                    if ("value" in an && an.value) {
                        answerValue = an.value;
                    }
                    if (answerResponse) {
                        if (answerResponse.params && "title" in answerResponse.params) {
                            if (answerValue) {
                                resultArray.push(`${answerValue / 10} см`);
                            }
                            else {
                                const title = stepResponse.questions
                                    .find((quest) => quest.id === questId)
                                    ?.answers.find((answer) => answer.id === an.id)?.params.title;
                                if (title)
                                    resultArray.push(title);
                            }
                        }
                    }
                }
            }
            return resultArray.join(", ");
        };
        watch(props.request, (newRec) => {
            // console.log("sfdljhsd", newRec);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.request.length ? props.request : emptyData, (item) => {
                    return (_openBlock(), _createBlock(ItemResponse, {
                        key: item.id,
                        item: {
                            title: !_ctx.request.length ? '' : getTitle(item.id),
                            response: !_ctx.request.length ? '' : getAnswer(item.id),
                        }
                    }, null, 8, ["item"]));
                }), 128))
            ]));
        };
    }
});

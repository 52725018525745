import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "flex px-2 align-items-center justify-content-between" };
const _hoisted_2 = { class: "text-sm font-semibold" };
const _hoisted_3 = { class: "text-xs" };
const _hoisted_4 = {
    key: 0,
    class: "flex px-2 align-items-center justify-content-start gap-2"
};
const _hoisted_5 = { class: "text-sm" };
const _hoisted_6 = {
    key: 0,
    class: "text-xs font-semibold"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeaderRequestBlock',
    props: {
        title: {},
        description: {},
        indexStep: {},
        countSteps: {},
        countQuestions: {},
        maxAnswers: {},
        selectedAnswers: {}
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(props.title), 1),
                    _createElementVNode("div", _hoisted_3, " Вопрос " + _toDisplayString(props.indexStep) + " из " + _toDisplayString(props.countSteps), 1)
                ]),
                (props.countQuestions === 1 && props.description !== '')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(props.description), 1),
                        (props.maxAnswers > 1)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Выбрано " + _toDisplayString(props.selectedAnswers) + " из " + _toDisplayString(props.maxAnswers), 1))
                            : _createCommentVNode("", true)
                    ]))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});

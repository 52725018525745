import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "w-full h-full flex flex-column" };
const _hoisted_2 = { style: { "margin-bottom": "0.875rem" } };
const _hoisted_3 = { class: "text-4xl font-semibold text-color" };
const _hoisted_4 = { class: "flex-grow-1 min-w-0 min-h-0" };
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ContentLayout',
    setup(__props) {
        const getService = (arrPath) => {
            let result = "";
            switch (arrPath[0]) {
                case "auto-kitchens":
                    result = "Авто-кухни";
                    break;
                default:
                    result = "3D планер";
                    break;
            }
            return result;
        };
        const getBreadcrumb = (arrPath) => {
            const arr = arrPath.value.slice(1);
            const result = [];
            for (const item of arr) {
                result.push({
                    label: item,
                });
            }
            return result;
        };
        const router = useRouter();
        const arrPath = ref(router.currentRoute.value.path.split("/").filter(Boolean));
        const titleService = ref(getService(arrPath.value));
        watch(() => router.currentRoute.value.path, (newRouter) => {
            arrPath.value = newRouter.split("/").filter(Boolean);
            titleService.value = getService(arrPath.value);
        });
        return (_ctx, _cache) => {
            const _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("section", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(titleService.value), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_router_view)
                ])
            ]));
        };
    }
});

export default class ArrayHelper {
    static findIndexById(list, id) {
        let index = -1;
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }
}
// export default ArrayHelper();

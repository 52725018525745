import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "logo cursor-pointer"
};
const _hoisted_2 = {
    class: "titleGroupLinks flex align-items-center",
    style: { "padding": "0" }
};
const _hoisted_3 = {
    key: 0,
    class: "text-nowrap textStyle"
};
const _hoisted_4 = { class: "flat flex-column mb-4" };
import ButtonHideSidbar from "@/components/buttons/sidebar/ButtonHideSidbar.vue";
import { computed, onMounted, ref } from "vue";
import { useSideMainStore } from "@/store/SideMainStore";
import router from "@/binaplan/router";
import User from "@/module/User";
import auth from "@/api/Auth";
import ButtonRouteSidbar from "@/components/buttons/sidebar/ButtonRouteSidbar.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'SidebarComponent',
    setup(__props) {
        const minimize = ref(false);
        const toggleMinimizeSidebar = () => {
            minimize.value = !minimize.value;
        };
        const sideMainStore = useSideMainStore();
        const activePage = computed(() => {
            let page = router.currentRoute.value.name;
            if (typeof router.currentRoute.value.meta !== "undefined") {
                page = router.currentRoute.value.meta.section ?? page;
            }
            return page;
        });
        const userGroups = ref(User.getMe().groups);
        const userEmail = auth.getEmail();
        const autoKitchenCollectionLinks = ref(sideMainStore.autoKitchenLinks);
        onMounted(async () => {
            await sideMainStore.requestCollectionLinks();
            autoKitchenCollectionLinks.value = sideMainStore.autoKitchenLinks;
            if (userEmail.length > 0) {
                // user.getMe();
            }
        });
        return (_ctx, _cache) => {
            const _component_Divider = _resolveComponent("Divider");
            const _component_Card = _resolveComponent("Card");
            return (_openBlock(), _createBlock(_component_Card, {
                class: _normalizeClass(["shadow-none", minimize.value ? 'sidebar_wrap_min' : 'sidebar_wrap_max']),
                pt: {
                    body: {
                        style: `padding: 1.25rem 0`,
                    },
                }
            }, {
                title: _withCtx(() => [
                    _createElementVNode("div", {
                        class: _normalizeClass(["head flex", minimize.value ? 'justify-content-center' : 'justify-content-between'])
                    }, [
                        (!minimize.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
                                _createElementVNode("img", {
                                    src: "/assets/images/logo.svg",
                                    alt: "main_logo"
                                }, null, -1)
                            ])))
                            : _createCommentVNode("", true),
                        _createVNode(ButtonHideSidbar, {
                            label: "Свернуть",
                            icon: minimize.value ? 'pi-angle-double-right' : 'pi-angle-double-left',
                            minimize: minimize.value,
                            onClick: toggleMinimizeSidebar
                        }, null, 8, ["icon", "minimize"])
                    ], 2)
                ]),
                content: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(autoKitchenCollectionLinks.value, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: item.name
                        }, [
                            _createElementVNode("div", _hoisted_2, [
                                _createVNode(_component_Divider, {
                                    align: "left",
                                    type: "solid"
                                }, {
                                    default: _withCtx(() => [
                                        (!minimize.value)
                                            ? (_openBlock(), _createElementBlock("b", _hoisted_3, _toDisplayString(item.label), 1))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                }, 1024)
                            ]),
                            _createElementVNode("div", _hoisted_4, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.child, (child) => {
                                    return (_openBlock(), _createBlock(ButtonRouteSidbar, {
                                        key: child.label,
                                        data: child,
                                        minimize: minimize.value
                                    }, null, 8, ["data", "minimize"]));
                                }), 128))
                            ])
                        ]));
                    }), 128))
                ]),
                _: 1
            }, 8, ["class"]));
        };
    }
});

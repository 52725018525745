import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "max-h-full flex flex-column select-none" };
const _hoisted_2 = { class: "header max-w-full border-bottom-2 border-gray-300 flex align-items-center justify-content-between" };
const _hoisted_3 = {
    class: "min-w-0 flex align-items-center justify-content-start",
    style: { "gap": "2px" }
};
const _hoisted_4 = { class: "surface-overlay white-space-nowrap overflow-hidden text-overflow-ellipsis" };
const _hoisted_5 = { class: "min-h-0 flex-grow-1 flex flex-column px-1_75 py-1_375" };
const _hoisted_6 = { class: "flex align-items-center justify-content-end" };
const _hoisted_7 = { class: "h-full flex align-items-center justify-content-center gap-2" };
const _hoisted_8 = { class: "flex flex-column" };
const _hoisted_9 = ["for"];
const _hoisted_10 = { class: "flex flex-column" };
import ModelIframe from "@/binaplan/components/planner/Wp3d/ModelIframe.vue";
import ButtonCustom from "@/components/buttons/ButtonCustom.vue";
import { ref } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'CardModel',
    props: {
        data: {}
    },
    emits: ["closeModel"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const isHiddenModel = ref(false);
        const emit = __emit;
        console.log("KJAHSDKJASHJD", props.data);
        const handleCloseCardModel = () => {
            emit("closeModel"); // Уведомляем родительский компонент
        };
        /*
         * Конвертирует values.id из string в number
         * */
        const convertedValues = (values) => {
            return values.map((v) => ({ ...v, id: Number(v.id) }));
        };
        console.log("Data", props.data);
        return (_ctx, _cache) => {
            const _component_Button = _resolveComponent("Button");
            const _component_AccordionTab = _resolveComponent("AccordionTab");
            const _component_Accordion = _resolveComponent("Accordion");
            const _component_Dropdown = _resolveComponent("Dropdown");
            const _component_TabPanel = _resolveComponent("TabPanel");
            const _component_Column = _resolveComponent("Column");
            const _component_TreeTable = _resolveComponent("TreeTable");
            const _component_TabView = _resolveComponent("TabView");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.data.name), 1),
                        _createVNode(ButtonCustom, {
                            icon: "pi-window-maximize",
                            "rounded-circle": "",
                            "icon-color": "#ceceda"
                        })
                    ]),
                    _createVNode(ButtonCustom, {
                        icon: "pi-times",
                        "rounded-circle": "",
                        onClick: handleCloseCardModel
                    })
                ]),
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_Button, {
                                icon: "pi pi-sync",
                                rounded: ""
                            }),
                            _createVNode(_component_Button, {
                                icon: "pi pi-check",
                                label: "Сохранить",
                                rounded: "",
                                iconPos: "right"
                            })
                        ])
                    ]),
                    _createVNode(_component_Accordion, { activeIndex: 0 }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AccordionTab, {
                                header: "Показать в 3D планере",
                                pt: {
                                    content: {
                                        class: 'p-0 overflow-hidden',
                                        style: 'height: 208px',
                                    },
                                }
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(ModelIframe, {
                                        catalogId: _ctx.data.catalog.externalId,
                                        modelId: _ctx.data.id,
                                        "no-rounded": ""
                                    }, null, 8, ["catalogId", "modelId"])
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_TabView, {
                        class: "min-h-0 flex-grow-1 flex flex-column",
                        pt: {
                            panelContainer: {
                                class: 'min-h-0 h-full scroll-container',
                            },
                        }
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_TabPanel, { header: 'Свойства модели' }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_8, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.properties, (item) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                                class: "flex flex-column mb-4 w-full",
                                                key: item.id
                                            }, [
                                                _createElementVNode("label", {
                                                    for: `input_${item.id}`
                                                }, _toDisplayString(item.name), 9, _hoisted_9),
                                                _createVNode(_component_Dropdown, {
                                                    inputId: `input_${item.id}`,
                                                    modelValue: item.value,
                                                    "onUpdate:modelValue": ($event) => ((item.value) = $event),
                                                    options: convertedValues(item.values),
                                                    optionLabel: "value",
                                                    optionValue: "id",
                                                    placeholder: "Выберите значение",
                                                    checkmark: "",
                                                    highlightOnSelect: false,
                                                    class: "w-full",
                                                    onChange: ($event) => (console.log(item))
                                                }, null, 8, ["inputId", "modelValue", "onUpdate:modelValue", "options", "onChange"])
                                            ]));
                                        }), 128))
                                    ])
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_TabPanel, { header: 'Вариантные свойства' }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_10, [
                                        _createVNode(_component_TreeTable, {
                                            value: _ctx.data.variantProperties
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_Column, { header: "ID" }, {
                                                    body: _withCtx(({ node }) => [
                                                        _createElementVNode("span", null, _toDisplayString(node.id), 1)
                                                    ]),
                                                    _: 1
                                                }),
                                                _createVNode(_component_Column, { header: "Наименование" }, {
                                                    body: _withCtx(({ node }) => [
                                                        _createElementVNode("span", null, _toDisplayString(node.property.name), 1)
                                                    ]),
                                                    _: 1
                                                }),
                                                _createVNode(_component_Column, {
                                                    field: "property",
                                                    header: "Описание"
                                                }, {
                                                    body: _withCtx(({ node }) => [
                                                        _createElementVNode("span", null, _toDisplayString(node.property.description), 1)
                                                    ]),
                                                    _: 1
                                                }),
                                                _createVNode(_component_Column, { header: "" }, {
                                                    body: _withCtx(() => [
                                                        _createVNode(_component_Button, {
                                                            icon: "pi pi-eye",
                                                            outlined: ""
                                                        })
                                                    ]),
                                                    _: 1
                                                })
                                            ]),
                                            _: 1
                                        }, 8, ["value"])
                                    ])
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })
                ])
            ]));
        };
    }
});

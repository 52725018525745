import { createApp } from "vue";
import App from "./binaplan/App.vue";
import { createPinia } from "pinia";
import router from "./binaplan/router";
import notifications from "@kyvg/vue3-notification";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/tailwind-light/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import Button from "primevue/button";
import Menu from "primevue/menu";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Sidebar from "primevue/sidebar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import TreeTable from "primevue/treetable";
import Tree from "primevue/tree";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import Toolbar from "primevue/toolbar";
import FileUpload from "primevue/fileupload";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Tag from "primevue/tag";
import RadioButton from "primevue/radiobutton";
import InputNumber from "primevue/inputnumber";
import DataTable from "primevue/datatable";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Rating from "primevue/rating";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Checkbox from "primevue/checkbox";
import Image from "primevue/image";
import ProgressSpinner from "primevue/progressspinner";
import Steps from "primevue/steps";
import Skeleton from "primevue/skeleton";
import Avatar from "primevue/avatar";
import Divider from "primevue/divider";
import Galleria from "primevue/galleria";
import Tooltip from "primevue/tooltip";
import Breadcrumb from "primevue/breadcrumb";
import ToastService from "primevue/toastservice";
import Ripple from "primevue/ripple";
import StyleClass from "primevue/styleclass";
import BadgeDirective from "primevue/badgedirective";
/* from https://fontawesome.com/docs/web/use-with/vue/add-icons */
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* find icon https://fontawesome.com/icons */
import { fas as solid } from "@fortawesome/free-solid-svg-icons";
import { far as regular } from "@fortawesome/free-regular-svg-icons";
library.add(solid);
library.add(regular);
import { loadFonts } from "./plugins/webfontloader";
loadFonts();
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "./styles/main.css";
import "./styles/sidebar.css";
import "./styles/media.css";
import "./styles/themes/theme.scss";
import RequestUser from "@/api/User";
import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css";
import ContextMenu from "@imengyu/vue3-context-menu";
import "vue-color-kit/dist/vue-color-kit.css";
import "primeicons/primeicons.css";
const pinia = createPinia();
const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = (event) => {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                binding.value();
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: (el) => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
};
const app = createApp(App)
    .directive("click-outside", clickOutside)
    .component("font-awesome-icon", FontAwesomeIcon);
function mount(app) {
    app.use(PrimeVue);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Button", Button);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Menu", Menu);
    app.component("RadioButton", RadioButton);
    app.component("InputNumber", InputNumber);
    app.component("IconField", IconField);
    app.component("InputIcon", InputIcon);
    app.component("InputText", InputText);
    app.component("InputSwitch", InputSwitch);
    app.component("DataTable", DataTable);
    app.component("FloatLabel", FloatLabel);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Card", Card);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Rating", Rating);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Accordion", Accordion);
    app.component("AccordionTab", AccordionTab);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Sidebar", Sidebar);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Toolbar", Toolbar);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Divider", Divider);
    app.component("TabView", TabView);
    app.component("TabPanel", TabPanel);
    app.component("TreeTable", TreeTable);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Tree", Tree);
    app.component("FileUpload", FileUpload);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Checkbox", Checkbox);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Image", Image);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Galleria", Galleria);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Dialog", Dialog);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Tag", Tag);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Avatar", Avatar);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Textarea", Textarea);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Column", Column);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Dropdown", Dropdown);
    app.component("MultiSelect", MultiSelect);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Splitter", Splitter);
    app.component("SplitterPanel", SplitterPanel);
    app.component("ProgressSpinner", ProgressSpinner);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Skeleton", Skeleton);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Steps", Steps);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Breadcrumb", Breadcrumb);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("Toast", Toast);
    app.directive("ripple", Ripple);
    app.directive("tooltip", Tooltip);
    app.directive("styleclass", StyleClass);
    app.directive("badge", BadgeDirective);
    app.use(ToastService);
    app.use(pinia);
    app.use(router);
    app.use(ContextMenu);
    app.use(notifications);
    app.mount("#app");
}
(async () => {
    await RequestUser.getMe()
        .then((res) => {
        if (res !== null) {
            if (res.getType() === "success") {
                sessionStorage.setItem("me", JSON.stringify(res.getResponse()));
            }
        }
        mount(app);
    })
        .catch(() => {
        mount(app);
    });
    //
    //
})();

import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = ["id"];
const _hoisted_2 = {
    class: "flex align-items-center justify-content-start",
    style: { "gap": "0.438rem" }
};
const _hoisted_3 = {
    key: 0,
    class: "textStyle"
};
const _hoisted_4 = {
    key: 0,
    style: { "font-size": "0.875rem", "color": "#47475a" },
    class: "pi pi-angle-right"
};
const _hoisted_5 = ["href", "onClick"];
const _hoisted_6 = { class: "ml-2 textStyle" };
import Router from "@/binaplan/router";
import { computed, ref } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ButtonRouteSidbar',
    props: {
        data: {},
        minimize: { type: Boolean }
    },
    setup(__props) {
        const props = __props;
        const getRandomIdButton = (length) => {
            const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
            let result = "";
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        };
        const idButton = getRandomIdButton(10);
        const isActionLink = computed(() => {
            let result = false;
            if (props.data.path && !props.data.child) {
                result = Router.currentRoute.value.path === props.data.path;
            }
            else {
                const childAction = props.data.child.find((child) => child.path === Router.currentRoute.value.path);
                if (childAction)
                    result = true;
            }
            return result;
        });
        console.log(props.data);
        const route = (path) => {
            Router.push(path);
        };
        const menu = ref();
        const toggle = (event) => {
            menu.value.toggle(event);
        };
        return (_ctx, _cache) => {
            const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
            const _component_router_link = _resolveComponent("router-link");
            const _component_Menu = _resolveComponent("Menu");
            const _directive_ripple = _resolveDirective("ripple");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", {
                    class: _normalizeClass(["wrapContainer relative flex align-items-center justify-content-between cursor-pointer", isActionLink.value ? 'activeLink' : '']),
                    id: _unref(idButton),
                    style: { "height": "35px" },
                    "aria-haspopup": "true",
                    "aria-controls": "overlay_menu",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.data.child && _ctx.data.child.length > 0
                        ? toggle($event)
                        : _ctx.data.path
                            ? route(_ctx.data.path)
                            : route('/')))
                }, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("i", {
                            style: { "font-size": "0.875rem" },
                            class: _normalizeClass(["iconStyle", `pi ${_ctx.data.icon}`])
                        }, null, 2),
                        (!props.minimize)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.data.label), 1))
                            : _createCommentVNode("", true)
                    ]),
                    (!props.minimize && _ctx.data.child && _ctx.data.child.length > 0)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                        : _createCommentVNode("", true)
                ], 10, _hoisted_1),
                _createVNode(_component_Menu, {
                    ref_key: "menu",
                    ref: menu,
                    id: "overlay_menu",
                    class: "overflow-y-auto",
                    appendTo: '#' + _unref(idButton),
                    model: _ctx.data.child,
                    popup: true,
                    style: { "position": "absolute", "left": "100%", "top": "0", "max-height": "23.5rem" },
                    pt: {
                        menu: {
                            style: 'padding-left: 0',
                        },
                    }
                }, {
                    item: _withCtx(({ item, props }) => [
                        (item.path)
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                style: { "height": "35px" },
                                to: item.path,
                                custom: ""
                            }, {
                                default: _withCtx(({ href, navigate }) => [
                                    _withDirectives((_openBlock(), _createElementBlock("a", _mergeProps({ href: href }, props.action, { onClick: navigate }), [
                                        _createVNode(_component_font_awesome_icon, {
                                            icon: item.icon,
                                            class: "iconStyle",
                                            style: { "margin-right": "5px", "font-size": "0.875rem" }
                                        }, null, 8, ["icon"]),
                                        _createElementVNode("span", _hoisted_6, _toDisplayString(item.label), 1)
                                    ], 16, _hoisted_5)), [
                                        [_directive_ripple]
                                    ])
                                ]),
                                _: 2
                            }, 1032, ["to"]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["appendTo", "model"])
            ], 64));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row h-full flex flex-column" };
const _hoisted_2 = { class: "col-lg-12 flex-grow-1" };
const _hoisted_3 = { class: "card h-full" };
const _hoisted_4 = { class: "card-body min-body flex flex-column h-full" };
const _hoisted_5 = { class: "flex flex-row flex-grow-1 gap-2" };
const _hoisted_6 = { class: "w-30 flex flex-column gap-2" };
const _hoisted_7 = { class: "flex-grow-1" };
import TitlePage from "@/binaplan/components/layouts/TitlePageContainer.vue";
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import TestForm from "@/binaplan/views/auto-kitchens/surveyConfigurator/TestForm/TestForm.vue";
import AutoKitchen from "@/module/AutoKitchen";
export default /*@__PURE__*/ _defineComponent({
    __name: 'SurveyConfiguratorView',
    setup(__props) {
        const toast = useToast();
        const testFormDialog = ref(false);
        const selectedCollection = ref();
        const collectionLinks = ref();
        const openTestForm = () => {
            testFormDialog.value = true;
        };
        const closeTestForm = () => {
            testFormDialog.value = false;
        };
        AutoKitchen.getCollections().then((res) => {
            if (res) {
                collectionLinks.value = res.getResponse();
                console.log(collectionLinks.value);
            }
        });
        return (_ctx, _cache) => {
            const _component_Button = _resolveComponent("Button");
            const _component_Dropdown = _resolveComponent("Dropdown");
            const _component_Toolbar = _resolveComponent("Toolbar");
            const _component_Skeleton = _resolveComponent("Skeleton");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(TitlePage, { "title-page": "Конфигуратор опроса" }),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_Toolbar, { class: "p-2 mb-4" }, {
                                start: _withCtx(() => [
                                    _createVNode(_component_Button, {
                                        label: "Форма опроса",
                                        icon: "pi pi-list",
                                        severity: "success",
                                        disabled: !selectedCollection.value,
                                        class: "mr-2 p-2 rounded text-sm",
                                        onClick: openTestForm
                                    }, null, 8, ["disabled"]),
                                    _createVNode(_component_Dropdown, {
                                        modelValue: selectedCollection.value,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((selectedCollection).value = $event)),
                                        options: collectionLinks.value,
                                        optionLabel: "name",
                                        placeholder: "Select a City",
                                        class: "w-full md:w-14rem",
                                        pt: {
                                            input: {
                                                class: 'p-2 text-sm',
                                            },
                                            list: {
                                                class: 'pl-0',
                                            },
                                            item: {
                                                class: 'p-1rem text-sm',
                                            },
                                        }
                                    }, null, 8, ["modelValue", "options"])
                                ]),
                                _: 1
                            }),
                            _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("div", _hoisted_6, [
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        borderRadius: "10px"
                                    }),
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        borderRadius: "10px"
                                    }),
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        borderRadius: "10px"
                                    }),
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        borderRadius: "10px"
                                    }),
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        borderRadius: "10px"
                                    }),
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        borderRadius: "10px"
                                    }),
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        borderRadius: "10px"
                                    }),
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        borderRadius: "10px"
                                    }),
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        borderRadius: "10px"
                                    }),
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        borderRadius: "10px"
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_7, [
                                    _createVNode(_component_Skeleton, {
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px"
                                    })
                                ])
                            ])
                        ]),
                        _createVNode(TestForm, {
                            "active-test-form": testFormDialog.value,
                            collectionId: Number(selectedCollection.value?.id),
                            collectionCode: selectedCollection.value?.code,
                            onSetStateForm: closeTestForm
                        }, null, 8, ["active-test-form", "collectionId", "collectionCode"])
                    ])
                ])
            ]));
        };
    }
});

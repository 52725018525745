import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "h-full w-full"
};
const _hoisted_2 = ["src"];
import { isNumber } from "lodash";
import { onMounted, ref } from "vue";
import PlannerBackend from "@/api/WebPlanner";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ModelIframe',
    props: {
        modelId: isNumber,
        catalogId: isNumber,
        noRounded: {
            type: Boolean,
            default: false,
        },
    },
    setup(__props) {
        const props = __props;
        const iframeSrc = ref("");
        onMounted(async () => {
            console.log("getModelIframeLink", props);
            await PlannerBackend.getModelIframeLink(props.catalogId.toString(), props.modelId.toString()).then((res) => {
                console.log(res);
                if (res?.getType() === "success") {
                    // console.log(res.getResponse().link);
                    if (res.getResponse()?.link) {
                        //const url = new URL(res.getResponse().link);
                        const link = res.getResponse().link;
                        const newLink = new URL(link);
                        const newClass = "binaplan-autoKitchen-modelView";
                        newLink.searchParams.set("add-class", newClass);
                        iframeSrc.value = newLink.toString();
                    }
                }
            });
        });
        return (_ctx, _cache) => {
            return (iframeSrc.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("iframe", {
                        class: _normalizeClass([{ 'iframe-rounded': !__props.noRounded }]),
                        src: iframeSrc.value
                    }, null, 10, _hoisted_2)
                ]))
                : _createCommentVNode("", true);
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex flex-row flex-wrap gap-2 overflow-y-auto w-full h-20rem px-2" };
import { onMounted, ref, watch } from "vue";
import CardAnswer from "@/binaplan/components/autoKitchen/requestBlock/content/CardAnswer.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'checksBlock',
    props: {
        data: {},
        stepId: {},
        request: {}
    },
    emits: ["checkedAnswer"],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const answers = ref([]);
        const initEmit = (data) => {
            emit("checkedAnswer", data);
        };
        watch(answers.value, (newAnswers) => {
            const an = getAnswer();
            if (an)
                if (!an.questions[0].answers.length) {
                    newAnswers[0].params.state = true;
                }
            if (newAnswers) {
                initEmit(getAnswer());
            }
        });
        onMounted(() => {
            for (const answer of props.data.questions[0].answers) {
                const index = props.data.questions[0].answers.findIndex((item) => item.id === answer.id);
                answers.value.push({
                    id: answer.id,
                    params: {
                        title: answer.params.title,
                        description: answer.params.description,
                        price: answer.params.price,
                        image: answer.params.image,
                        state: !index,
                    },
                });
            }
            initEmit(getAnswer());
        });
        const getAnswer = () => {
            const step = props.request.find((step) => step.id === props.data.id);
            if (step) {
                const newStep = {
                    id: step.id,
                    questions: [],
                };
                for (const quest of step.questions) {
                    const newQuest = {
                        id: quest.id,
                        answers: [],
                    };
                    for (const answer of answers.value) {
                        if (answer.params.state) {
                            const newAnswer = {
                                id: answer.id,
                            };
                            newQuest.answers.push(newAnswer);
                        }
                    }
                    newStep.questions.push(newQuest);
                }
                return newStep;
            }
        };
        return (_ctx, _cache) => {
            const _component_Checkbox = _resolveComponent("Checkbox");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(answers.value, (answer) => {
                    return (_openBlock(), _createBlock(CardAnswer, {
                        key: answer.id,
                        answer: answer
                    }, {
                        check: _withCtx(() => [
                            _createVNode(_component_Checkbox, {
                                class: "absolute left-0 top-0",
                                modelValue: answer.params.state,
                                "onUpdate:modelValue": ($event) => ((answer.params.state) = $event),
                                onClick: getAnswer,
                                binary: true,
                                pt: {
                                    root: {
                                        style: {
                                            width: '100%',
                                            height: '100%',
                                        },
                                    },
                                    box: {
                                        style: {
                                            position: 'absolute',
                                            left: '10px',
                                            top: '10px',
                                        },
                                    },
                                }
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                    }, 1032, ["answer"]));
                }), 128))
            ]));
        };
    }
});
